/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-len */
import { CustomCategoryFolder } from './CustomCategoryFolder';
import { CustomCategoryMultimediaItem } from './CustomCategoryMultimediaItem';
import Common from '@/helper/Common';
import { BaseSignalRModelRequestJSON } from '@/signalRRequest/SignalRModelRequest';
import { MapSupportingItem } from '../MapSupportingItem';
import { ProgramItem } from '../Program/ProgramItem';
import { LinkedParticipantsItem } from '../CustomCategory/LinkedParticipantsItem';
import { ParticipantGroups } from '@/entities/ParticipantGroups';
import { VodDetail } from '@/entities/VodDetail';

export class CustomCategoryItem implements MapSupportingItem {
  public static createEmpty(): CustomCategoryItem {
    return new CustomCategoryItem();
  }


  public static GetCustomCategoryItemFromCustomCategoryItemSignalR(item: CustomCategoryItem, customCategoryItem: CustomCategoryItemSignalR): CustomCategoryItem {
    item.CustomCategoryId = customCategoryItem.CustomCategoryId;
    item.Title = customCategoryItem.Title;
    item.ThumbUrl = customCategoryItem.ThumbUrl;
    item.IsLinked = customCategoryItem.IsLinked;
    item.FolderName = customCategoryItem.FolderName;
    item.Description = customCategoryItem.Description;
    item.IconUrl = customCategoryItem.IconUrl;
    item.CategoryFolder = customCategoryItem.CategoryFolder;
    item.Location = customCategoryItem.Location;
    item.Latitude = customCategoryItem.Latitude;
    item.Longitude = customCategoryItem.Longitude;
    item.Address = customCategoryItem.Address;
    item.City = customCategoryItem.City;
    item.MainMediaFullUrl = customCategoryItem.MainMediaFullUrl;
    item.FeaturedImageUrl = customCategoryItem.FeaturedImageUrl;
    item.FeaturedOrginalImageUrl = customCategoryItem.FeaturedOrginalImageUrl;
    item.FeaturedImageWidth = customCategoryItem.FeaturedImageWidth;
    item.FeaturedImageHeight = customCategoryItem.FeaturedImageHeight;
    item.TopPosition = customCategoryItem.TopPosition;
    item.LeftPosition = customCategoryItem.LeftPosition;
    item.ShowFeatureImageBelowTitle = customCategoryItem.ShowFeatureImageBelowTitle;
    item.ContentType = customCategoryItem.ContentType;
    item.ContentUrl = customCategoryItem.ContentUrl;
    item.LinkedEvents = customCategoryItem.LinkedEvents;
    item.ContentTypeV2 = customCategoryItem.ContentTypeV2;
    item.ParticipantGroupIds = customCategoryItem.ParticipantGroupIds;
    item.ParticipantGroups = customCategoryItem.ParticipantGroups;
    item.Company = customCategoryItem.Company;
    item.JobTitle = customCategoryItem.JobTitle;
    item.HideFromEventWebpage = customCategoryItem.HideFromEventWebpage;
    item.IsFullImage = customCategoryItem.IsFullImage;
    return item;
  }

  constructor(
    public CustomCategoryId: number= 0,
    public Title: string= '',
    public ThumbUrl: string = '',
    public IsLinked: boolean= false,
    public FolderName: string= '',
    public Description: string = '',
    public ApplicationId: number = 0, public ApplicationInstanceId: number = 0,
    public MainMediaFullUrl: string = '',
    public Location: string = '',
    public Address: string = '', public City: string = '',
    public Latitude: number = 0, public Longitude: number = 0,
    public IconUrl: string = '',
    public FeaturedOrginalImageUrl: string = '',
    public FeaturedImageUrl: string = '',
    public LinkedPrograms?: ProgramItem[] | [],
    public CategoryFolder?: CustomCategoryFolder | null,
    public CustomCategoryMedias?: CustomCategoryMultimediaItem[] | CustomCategoryMultimediaItem[],
    public CustomCategoryMedias360?: CustomCategoryMultimediaItem[] | CustomCategoryMultimediaItem[],
    public styleDefaultDiv: string = '#f9f9f9',
    public modifedDate: Date = new Date(),
    public DisableItem: boolean = false,
    public FeaturedImageWidth: number = 320, // default featured image width
    public FeaturedImageHeight: number = 200, // default featured image height
    public TopPosition: string = '0',
    public LeftPosition: string = '0',
    public ShowFeatureImageBelowTitle: boolean = false,
    public ContentType: string = 'Normal',
    public ExternalContentType: string = 'URL',
    public SortOrder: number = 0,
    public ContentUrl: string = '',
    public LinkedEvents: number[]= [],
    public ContentTypeV2: string = '',
    public LinkedParticipantsItems?: LinkedParticipantsItem[] | [],
    public ParticipantGroups: ParticipantGroups[] = [],
    public ParticipantGroupIds: number[] = [],
    public VodDetail?: VodDetail,
    public IsVodDetail: boolean = false,
    public JobTitle: string = '',
    public Company: string = '',
    public HideFromEventWebpage: boolean = false,
    public IsFullImage: boolean = false,
  ) {}


  public CompareCustomCategoryObjects({ val1, val2 }: { val1: CustomCategoryItem; val2: CustomCategoryItem }): boolean {
    const customCategoryItem1 = CustomCategoryItem.prototype.SetCustomCategoryObject(val1);
    const customCategoryItem2 = CustomCategoryItem.prototype.SetCustomCategoryObject(val2);
    // check for old data which doesn't have p tag in description
    if (!customCategoryItem2.Description.startsWith('<p>')) {
      customCategoryItem1.Description = customCategoryItem1.Description.trim().replace(/\s/g, '');
      customCategoryItem2.Description = customCategoryItem2.Description.trim().replace(/\s/g, '');
      customCategoryItem2.Description = '<p>' + customCategoryItem2.Description + '</p>';
      customCategoryItem1.Description = this.StripHtml(String(customCategoryItem1.Description));
      customCategoryItem2.Description = this.StripHtml(String(customCategoryItem2.Description));
    }
    if (Common.prototype.CompareObjects(customCategoryItem1, customCategoryItem2)) {
      return true;
    } else {
      return false;
    }
  }
  public StripHtml(html: string): string {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }
  public SetCustomCategoryObject(val: CustomCategoryItem): any {
    return {
      Title: val.Title,
      Description: val.Description,
      IconUrl: val.IconUrl,
      MainMediaFullUrl: val.MainMediaFullUrl,
      Location: val.Location,
      Address: val.Address,
      City: val.City,
      Latitude: val.Latitude,
      Longitude: val.Longitude,
      FeaturedOrginalImageUrl: val.FeaturedOrginalImageUrl,
      FeaturedImageUrl: val.FeaturedImageUrl,
      TopPosition: val.TopPosition,
      LeftPosition: val.LeftPosition,
      ShowFeatureImageBelowTitle: val.ShowFeatureImageBelowTitle,
      ContentType: val.ContentType,
      ContentUrl: val.ContentUrl,
      CategoryFolder: val.CategoryFolder,
      // LinkedEvents: val.LinkedEvents,
      ContentTypeV2: val.ContentTypeV2,
      ParticipantGroups: val.ParticipantGroups,
      ParticipantGroupIds: val.ParticipantGroupIds,
      IsVodDetail: val.IsVodDetail,
      Company: val.Company,
      JobTitle: val.JobTitle,
      HideFromEventWebpage: val.HideFromEventWebpage,
      IsFullImage: val.IsFullImage,
    };
  }

}

export class CustomCategoryItemResult {

  constructor(public ValidationErrors: string[], public CustomCategory: CustomCategoryItem, public Folders: string[]) { }
}

export class CustomCategoryItemSignalR extends BaseSignalRModelRequestJSON {
  public static createEmpty(): CustomCategoryItemSignalR {
    return new CustomCategoryItemSignalR();
  }
  public static GetCustomCategorySignalRItem(customCategoryItem: CustomCategoryItem): CustomCategoryItemSignalR {
    const item: CustomCategoryItemSignalR = CustomCategoryItemSignalR.createEmpty();
    item.CustomCategoryId = customCategoryItem.CustomCategoryId;
    item.Title = customCategoryItem.Title;
    item.ThumbUrl = customCategoryItem.ThumbUrl;
    item.IsLinked = customCategoryItem.IsLinked;
    item.FolderName = customCategoryItem.FolderName;
    item.Description = customCategoryItem.Description;
    item.IconUrl = customCategoryItem.IconUrl;
    item.CategoryFolder = customCategoryItem.CategoryFolder;
    item.Location = customCategoryItem.Location;
    item.Latitude = customCategoryItem.Latitude;
    item.Longitude = customCategoryItem.Longitude;
    item.Address = customCategoryItem.Address;
    item.City = customCategoryItem.City;
    item.MainMediaFullUrl = customCategoryItem.MainMediaFullUrl;
    item.FeaturedImageUrl = customCategoryItem.FeaturedImageUrl;
    item.FeaturedOrginalImageUrl = customCategoryItem.FeaturedOrginalImageUrl;
    item.FeaturedImageWidth = customCategoryItem.FeaturedImageWidth;
    item.FeaturedImageHeight = customCategoryItem.FeaturedImageHeight;
    item.TopPosition = customCategoryItem.TopPosition;
    item.LeftPosition = customCategoryItem.LeftPosition;
    item.selectedName = customCategoryItem.Title;
    item.selectedCount = 1;
    item.ShowFeatureImageBelowTitle = customCategoryItem.ShowFeatureImageBelowTitle;
    item.ContentType = customCategoryItem.ContentType;
    item.ContentUrl = customCategoryItem.ContentUrl;
    item.LinkedEvents = customCategoryItem.LinkedEvents;
    item.ContentTypeV2 = customCategoryItem.ContentTypeV2;
    item.ParticipantGroupIds = customCategoryItem.ParticipantGroupIds;
    item.ParticipantGroups = customCategoryItem.ParticipantGroups;
    item.Company = customCategoryItem.Company;
    item.JobTitle = customCategoryItem.JobTitle;
    item.HideFromEventWebpage = customCategoryItem.HideFromEventWebpage;
    item.IsFullImage = customCategoryItem.IsFullImage;
    return item;
  }
  constructor(
    public CustomCategoryId: number= 0,
    public Title: string= '',
    public ThumbUrl: string = '',
    public IsLinked: boolean= false,
    public FolderName: string= '',
    public Description: string = '',
    public ApplicationId: number = 0, public ApplicationInstanceId: number = 0,
    public MainMediaFullUrl: string = '',
    public Location: string = '',
    public Address: string = '', public City: string = '',
    public Latitude: number = 0, public Longitude: number = 0,
    public StartDate: Date = new Date(),
    public EndDate: Date = new Date(),
    public IconUrl: string = '',
    public FeaturedOrginalImageUrl: string = '',
    public FeaturedImageUrl: string = '',
    public CategoryFolder?: CustomCategoryFolder | null,
    public styleDefaultDiv: string = '#f9f9f9',
    public modifedDate: Date = new Date(),
    public DisableItem: boolean = false,
    public FeaturedImageWidth: number = 320, // default featured image width
    public FeaturedImageHeight: number = 200, // default featured image height
    public TopPosition: string = '0',
    public LeftPosition: string = '0',
    public ShowFeatureImageBelowTitle: boolean = false,
    public ContentType: string = 'Normal',
    public ContentUrl: string = '',
    public LinkedEvents: number[]= [],
    public ContentTypeV2: string = '',
    public ParticipantGroups: ParticipantGroups[] = [],
    public ParticipantGroupIds: number[] = [],
    public JobTitle: string = '',
    public Company: string = '',
    public HideFromEventWebpage: boolean = false,
    public IsFullImage: boolean = false,
  ) {
    super(0, '');
  }
}

export class CustomCategoryItemDetailSignalR extends BaseSignalRModelRequestJSON {
  public static createEmpty(): CustomCategoryItemDetailSignalR {
    return new CustomCategoryItemDetailSignalR();
  }
  public static GetCustomCategoryDetailSignalRItem(customCategoryDetailItem: any): CustomCategoryItemDetailSignalR {
    const item: CustomCategoryItemDetailSignalR = CustomCategoryItemDetailSignalR.createEmpty();
    item.selectedName = customCategoryDetailItem.Title;
    item.selectedCount = 1;
    item.dataItem = customCategoryDetailItem.dataItem;
    item.id = customCategoryDetailItem.id;
    item.customCategoryDetailType = customCategoryDetailItem.customCategoryDetailType;
    return item;
  }
  constructor(
    public dataItem: any = [],
    public id: number = 0,
    public customCategoryDetailType: string = '',
  ) {
    super(0, '');
  }
}
