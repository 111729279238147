
import { Component, Vue, Prop, Watch, toNative } from 'vue-facing-decorator';
import { LinkedParticipantsItem } from '@/entities/CustomCategory/LinkedParticipantsItem';
import { SearchItem } from '@/entities/SearchItem';
import Avatar from 'vue3-avatar';
// import  CustomCategory  from '@/views/CustomCategory.vue';
import { CustomCategoryItem } from '@/entities/CustomCategory/CustomCategoryItem';
import { AppColorSettingsInfo } from '@/entities/Application/AppColorSettingsInfo';
import { StoreHelper } from '@/store/StoreHelper';
import { Store, Root } from '@/main';
import ChangeVisibilitySearch from '@/entities/Emits/Information/ChangeVisibilitySearch';
import Common from '@/helper/Common';
@Component({
  components: {
    Avatar,
  },
})

class CustomCategoryLinkedParticipantListView extends Vue {
  // Properties
  @Prop() private headerText?: string;
  @Prop() private noProfileText?: string;
  @Prop() private btnText?: string;
  @Prop() private showAddButton?: boolean;
  @Prop() private selectedCustomTypeId?: number;
  @Prop() private selectedCustomCategoryItem?: CustomCategoryItem;
  @Prop() private allParticipants?: LinkedParticipantsItem[];
  // Local variables
  private loadingImage: string = require('@/assets/Images/loading.png'); // Loading image path
  private localHeaderText = '';
  private localNoProfileText = '';
  private localBtnText = '';
  private localShowAddButton = false;
  private localLinkedProgramLoading = false;
  private localAllParticipants: LinkedParticipantsItem[] = [];
  private SerachItems?: SearchItem[] = [];
  // private customCategory = new CustomCategory();
  private localSelectedCustomCategoryItem = new CustomCategoryItem();
  private storeHelper: StoreHelper = new StoreHelper(Store);
  // Called on mounted
  private mounted() {
    this.localHeaderText = this.headerText!;
    this.localNoProfileText = this.noProfileText!;
    this.localBtnText = this.btnText!;
    this.localShowAddButton = this.showAddButton!;
    this.localSelectedCustomCategoryItem = this.localSelectedCustomCategoryItem;
    this.localAllParticipants = this.allParticipants!;
    // Show loading
    Root.on('customCategoryDetailLoading', (loading: boolean) => {
      this.localLinkedProgramLoading = loading ;
    });
  }
  // get by default color for Background color
  private GetDefaultBackgroundColor() {
    const appDefaultColorsInfo: AppColorSettingsInfo = this.storeHelper.GetApplicationColorSettingsFromStore();
    return appDefaultColorsInfo.topNavBackgroundColor;
  }
  // get by default color for Foreground color
  private GetDefaultForegroundColor() {
    const appDefaultColorsInfo: AppColorSettingsInfo = this.storeHelper.GetApplicationColorSettingsFromStore();
    return appDefaultColorsInfo.topNavForegroundColor;
  }
  // Get Intials
  private GetIntials(firstName: string, lastName: string) {
    if(lastName !== '') {
      return firstName.charAt(0) + ' ' + lastName.charAt(0);
    } else {
      return firstName.charAt(0);
    }
  }
  // Icon url is valid or not if not then we are using avtar with name initials
  private IsIconUrlValid(iconUrl: string) {
    if(iconUrl !== '' && iconUrl !== null && iconUrl !== undefined) {
      return true;
    } else {
      return false;
    }
  }
  // Remove Item
  private RemoveItem(item: SearchItem) {
    const customCategoryIds: number[] = [this.selectedCustomTypeId!];
    const participantIds: number[] =[item.ParticipantId];
    const path = '/CustomCategory/RemoveParticpantsLinkToCustomCategory';
    Common.prototype.RemoveParticipansLink(path, customCategoryIds, participantIds);
    const objIndexLinkedParticipant = this.localAllParticipants.findIndex(((obj) => obj.ParticipantId===item.ParticipantId));
    this.localAllParticipants.splice(objIndexLinkedParticipant,1);
    this.localSelectedCustomCategoryItem.LinkedParticipantsItems = this.localAllParticipants;
    const customCategoryItemData = {
      dataItem: this.localSelectedCustomCategoryItem,
      customCategoryDetailType: 'LinkedParticipant',
    };
    Root.emit('linked-category-add-participant-notification', customCategoryItemData);
  }
  // Open the CommonSearchComponent.vue for searching in the participants so we can add and remove from there
  public ShowSearch(searchString: string) {
    const item: ChangeVisibilitySearch = ChangeVisibilitySearch.createEmpty();
    item.SearchPlaceholder = this.$t('SearchPlaceholder').toString();
    item.SearchTitle = this.localBtnText;
    item.SearchLinkedListing = this.localAllParticipants;
    item.SearchString = searchString;
    Root.emit('change-visibility-search',item);
  }
  // Watchs
  @Watch('btnText')
  private onBtnTextChanged(
    value: string,
  ) {
    this.localBtnText = value;
  }
  @Watch('noProfileText')
  private onNoProfileTextChanged(
    value: string,
  ) {
    this.localNoProfileText = value;
  }
  @Watch('showAddButton')
  private onshowAddButtonChanged(
    value: boolean,
  ) {
    this.localShowAddButton = value;
  }
  @Watch('headerText')
  private onHeaderTextChanged(
    value: string,
  ) {
    this.localHeaderText = value;
  }
  @Watch('selectedCustomCategoryItem')
  private onSelectedCustomCategoryItemChanged(
    value: CustomCategoryItem,
  ) {
    this.localSelectedCustomCategoryItem = value;
  }
  @Watch('allParticipants')
  private onAllParticipantsChanged(
    value: LinkedParticipantsItem[],
  ) {
    this.localAllParticipants = value;
  }
}
export default toNative(CustomCategoryLinkedParticipantListView);
