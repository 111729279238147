import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-159485d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "gridlisting" }
const _hoisted_2 = {
  key: 0,
  class: "margin-top-15"
}
const _hoisted_3 = { class: "edit-section" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  class: "pin-menu-icon"
}
const _hoisted_6 = { class: "edit-link" }
const _hoisted_7 = {
  id: "anchorCustomCategoryFolderEditIcon",
  class: "hyperLink",
  href: "javascript:void(0)"
}
const _hoisted_8 = {
  id: "divCustomCategoryFolderEditName",
  class: "item-position-from-image valign-parent50 folder-input"
}
const _hoisted_9 = ["placeholder"]
const _hoisted_10 = {
  key: 0,
  class: "error-vuelidate"
}
const _hoisted_11 = { class: "submit-position" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_loading, {
      "show-loading": _ctx.showLoading,
      "border-color": _ctx.loaderBorderColor
    }, null, 8, ["show-loading", "border-color"]),
    (_ctx.localFolderItem && !_ctx.showImageProcess)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              id: "divCustomCategoryFolderEditIcon",
              class: "img-select",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clickOnImage(true)))
            }, [
              (_ctx.localFolderItem.FontAwesomeIconId===0)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    id: "imgCustomCategoryFolderEditIcon",
                    src: _ctx.GetImageUrl(_ctx.localFolderItem.IconUrl),
                    class: "iconImg"
                  }, null, 8, _hoisted_4))
                : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("i", {
                      class: _normalizeClass([_ctx.localFolderItem.FontAwesomeIconName, "pinned-menu-icon"])
                    }, null, 2)
                  ])),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("a", _hoisted_7, _toDisplayString(_ctx.$t("Button.Edit")), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _withDirectives(_createElementVNode("input", {
                id: "inputCustomCategoryFolderEditName",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.v$.localFolderItem.Name.$model) = $event)),
                type: "text",
                class: _normalizeClass(["form-control valign width100", _ctx.status(_ctx.v$.localFolderItem.Name)]),
                placeholder: _ctx.GetPlaceholder(),
                maxlength: "70"
              }, null, 10, _hoisted_9), [
                [
                  _vModelText,
                  _ctx.v$.localFolderItem.Name.$model,
                  void 0,
                  { trim: true }
                ]
              ]),
              (_ctx.v$.localFolderItem.Name.$error)
                ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.$t("Validation.NameRequired")), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("button", {
                id: "btnPinCustomCategoryFolder",
                class: "btn btn-primary btn-size-default",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.PinToMenu()))
              }, [
                (_ctx.localFolderItem.PinnedToMenu)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.$t("Unpin")), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.$t("Pintomenu")), 1))
              ]),
              _createElementVNode("button", {
                id: "btnSaveCustomCategoryFolder",
                class: "btn btn-primary btn-size-default margin-left-15",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.Submit(_ctx.v$)))
              }, _toDisplayString(_ctx.$t("SaveButton")), 1),
              _createElementVNode("button", {
                id: "btnCancelCustomCategoryFolder",
                class: "btn btn-secondary btn-size-default margin-left-15",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.Cancel(_ctx.v$)))
              }, _toDisplayString(_ctx.$t("CancelButton")), 1)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}