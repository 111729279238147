import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-474ff5c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "dvFileupload" }
const _hoisted_2 = { class: "file-upload-container" }
const _hoisted_3 = { class: "fileUploadDiv file-upload-control" }
const _hoisted_4 = {
  key: 0,
  class: "file-upload-margin"
}
const _hoisted_5 = { class: "flex-class" }
const _hoisted_6 = { class: "file" }
const _hoisted_7 = ["accept", "name"]
const _hoisted_8 = { class: "file-custom border-radius-10" }
const _hoisted_9 = { class: "ajax-loader" }
const _hoisted_10 = ["src"]
const _hoisted_11 = {
  key: 0,
  class: "allow-extension-msg"
}
const _hoisted_12 = {
  key: 1,
  class: "file-upload-margin"
}
const _hoisted_13 = { class: "img-section flex-class" }
const _hoisted_14 = { class: "span-border" }
const _hoisted_15 = ["src"]
const _hoisted_16 = ["href"]
const _hoisted_17 = { class: "file-after" }
const _hoisted_18 = ["accept", "name"]
const _hoisted_19 = { class: "ajax-loader" }
const _hoisted_20 = ["src"]
const _hoisted_21 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", null, _toDisplayString(_ctx.localHeading), 1),
        (!_ctx.showReplaceSection)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("label", _hoisted_6, [
                  _createElementVNode("input", {
                    id: "uploadFile",
                    ref: "file",
                    type: "file",
                    accept: _ctx.localAllowedExtensions,
                    name: _ctx.uploadFile,
                    onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onFileChange($event.target.name, $event.target.files, false)))
                  }, null, 40, _hoisted_7),
                  _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.iconfilename), 1)
                ]),
                _withDirectives(_createElementVNode("div", _hoisted_9, [
                  _createElementVNode("img", {
                    class: "img-responsive ajax-loader-img",
                    src: _ctx.loadingImage,
                    alt: "Wait"
                  }, null, 8, _hoisted_10)
                ], 512), [
                  [_vShow, _ctx.ShowLoader]
                ])
              ]),
              (_ctx.localAllowedExtensions!='')
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t("AllowedExtension")) + " : " + _toDisplayString(_ctx.localAllowedExtensions), 1))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.showReplaceSection)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("span", _hoisted_14, [
                  _createElementVNode("img", {
                    src: _ctx.fileIconUrl,
                    class: "pdf-icon-img"
                  }, null, 8, _hoisted_15),
                  _createTextVNode("   "),
                  _createElementVNode("a", {
                    href: _ctx.fileUrl,
                    class: "replace-anchor",
                    target: "_blank"
                  }, _toDisplayString(_ctx.localOpenFileText), 9, _hoisted_16)
                ]),
                _createElementVNode("label", _hoisted_17, [
                  _createTextVNode(_toDisplayString(_ctx.$t('CustomCategory.Replace')) + " ", 1),
                  _createElementVNode("input", {
                    id: "uploadFile",
                    ref: "file",
                    type: "file",
                    accept: _ctx.localAllowedExtensions,
                    name: _ctx.uploadFile,
                    class: "input-visible",
                    onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onFileChange($event.target.name, $event.target.files, true)))
                  }, null, 40, _hoisted_18)
                ]),
                _withDirectives(_createElementVNode("div", _hoisted_19, [
                  _createElementVNode("img", {
                    class: "img-responsive ajax-loader-img",
                    src: _ctx.loadingImage,
                    alt: "Wait"
                  }, null, 8, _hoisted_20)
                ], 512), [
                  [_vShow, _ctx.ShowLoader]
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          (_ctx.errorText!='')
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                class: "errorText",
                innerHTML: _ctx.$t(_ctx.errorText,{size: _ctx.maxSize})
              }, null, 8, _hoisted_21))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}