
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
import { CustomCategoryTypes } from '@/entities/CustomCategory/CustomCategoryType';
import CustomCategoryTypeEnum from '@/enums/CustomCategoryTypeEnum';
import ImageProcess from '@/components/Common/ImageProcess.vue';
import Editor from '@/components/Common/Editor.vue';
import MapPicker from '@/components/Common/MapPicker.vue';
import { CustomCategoryItem } from '@/entities/CustomCategory/CustomCategoryItem';
import { FontAwesomeIcon } from '@/entities/FontAwesomeViewModel';
import loading from '@/components/Common/loading.vue';
import ValidationSummary from '@/components/Common/ValidationSummary.vue';
import { EventItem } from '@/entities/ApplicationInstance/EventItem';
import { CustomCategoryFolder } from '@/entities/CustomCategory/CustomCategoryFolder';
import { BaseCustomCategoryRenderer } from '@/entities/CustomCategory/FactoryService/BaseCustomCategoryRenderer';
import AddUrl from '@/components/Common/AddUrl.vue';
import InlineFileUploader from '@/components/Common/InlineFileUploader.vue';
import CustomCategoryLinkedProgramListView from '@/components/CustomCategory/CustomCategoryDetail/LinkedProgram/CustomCategoryLinkedProgramListView.vue';
import { ProgramItem } from '@/entities/Program/ProgramItem';
import AddProgramWithEntity from '@/components/AddProgram/AddProgramWithEntity.vue';
import {SignalRModelRequest} from '@/signalRRequest/SignalRModelRequest';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { CustomCategorySignalRModel } from '@/signalRRequest/CustomCategory/CustomCategorySignalRModel';
import { StoreHelper } from '@/store/StoreHelper';
import { CustomCategoryMultimediaItem } from '@/entities/CustomCategory/CustomCategoryMultimediaItem';
import CustomCategoryMultimediaListView from '@/components/CustomCategory/CustomCategoryDetail/Multimedia/CustomCategoryMultimediaListView.vue';
import FileUpload from '@/components/Common/FileUpload.vue';
import CustomCategoryShareWithOtherEvents from '@/components/CustomCategory/ShareWithOtherEvents/CustomCategoryShareWithOtherEvents.vue';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import CustomCategoryLinkedParticipantListView from '@/components/CustomCategory/CustomCategoryDetail/LinkParticipant/CustomCategoryLinkedParticipantListView.vue';
import { ParticipantGroups } from '@/entities/ParticipantGroups';
import ListOfParticipantGroups from '@/components/Common/ListOfParticipantGroups.vue';
import CommonSearchComponent from '@/components/Common/CommonSearchComponent.vue';
import { SearchItem } from '@/entities/SearchItem';
import { LinkedParticipantsItem } from '@/entities/CustomCategory/LinkedParticipantsItem';
import VideoOnDemandComponent from '@/components/Common/VirtualMeetings/VideoOnDemandComponent.vue';
import { VodEntityItemModel } from '@/entities/VodEntityItemModel';
import { VodDetail, VodVideoMetricsRequest } from '@/entities/VodDetail';
import { VirtualMeetingType } from '@/entities/VirtualMeetingType';
import { VodService }  from '@/entities/FactoryPattern/Vod/VodService';
import { VodBaseRenderModel } from '@/entities/FactoryPattern/Vod/VodBaseRenderModel';
import  MuxVideo  from '@/components/Common/MuxVideo.vue';
import VodTypeEnum from '@/enums/VodTypeEnums';
import PreRecordedMeetingHighcharts from '@/components/Common/PreRecordedMeetingHighcharts.vue';
import Common from '@/helper/Common';
import ModuleEnum from '@/enums/ModuleEnum';
import SubModulePrefix from '@/enums/SubModulePrefix';
import EventWebPageVisibiltity from '@/components/Common/EventWebPageVisibiltity.vue';
import { Store, Root, t, FetchData } from '@/main';
import ChangeVisibilitySearch from '@/entities/Emits/Information/ChangeVisibilitySearch';
import { reactive } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
@Component({
  components: {
    ImageProcess,
    ValidationSummary,
    loading,
    AddUrl,
    InlineFileUploader,
    Editor,
    MapPicker,
    CustomCategoryLinkedProgramListView,
    AddProgramWithEntity,
    CustomCategoryMultimediaListView,
    CustomCategoryShareWithOtherEvents,
    ModalPopup,
    FileUpload,
    CustomCategoryLinkedParticipantListView,
    CommonSearchComponent,
    ListOfParticipantGroups,
    VideoOnDemandComponent,
    MuxVideo,
    PreRecordedMeetingHighcharts,
    EventWebPageVisibiltity,
  },
})
class CustomCategoryChildComponent extends Vue {
  @Prop() private previouslySelectedFontAwesomeIco?: FontAwesomeIcon;
  @Prop() private selectedCustomCategoryItem?: CustomCategoryItem;
  @Prop() private availableEvents?: EventItem[];
  @Prop() private customCategoryTypeId?: number;
  @Prop() private applicationInstanceId?: number;
  @Prop() private selectedBaseCustomCategoryRenderer?: BaseCustomCategoryRenderer;
  @Prop() private availableCustomCategoryFolders?: CustomCategoryFolder[];
  @Prop() private participantGroups?: ParticipantGroups[];

  private readonly defaultImageWidth = 320;
  private readonly defaultImageHeight = 200;
  private readonly CustomCategoryModule = 'CustomCategory';
  private readonly video: string = 'Video';
  private readonly imageType360Item: string = '360image';
  private readonly NotificationCustomCategoryDeleted =
    'NotificationCustomCategoryDeleted';
  private readonly presentation: string = 'Presentation';
  private localselectedMeetingOption = 0;
  private virtualMeetingTypes: VirtualMeetingType[] = [];
  private customCategoryType = '';
  private missingImage: string = require('@/assets/Images/ImageMissing.png');
  private featureMissingImage: string = require('@/assets/Images/ImageMissingFeature.png');
  private missingVideo: string = require('@/assets/Images/Play_75x75.png');
  private deleteIcon = require('@/assets/Images/Delete-icon.svg');
  private isMobileView = false;
  private CustomCategoryTypes: any;
  private showImageProcess = false;
  private iconURL = '';
  private mainMediaFullUrl = '';
  private isShowCustomCategoryEditDetailView = true;
  private localCustomCategoryItem: CustomCategoryItem | null  = null;
  private isShowButtons = false;
  private loaderBorderColor = '';
  private showLoading = false;
  private modalPopupVisible = false;
  private visibleValidationSummary = false;
  private isValidURL = false;
  private isValidFileURL = false;
  private modalPopupContent = '';
  private defaultFolder: CustomCategoryFolder | null = null;
  private isDisabled = false;
  private selCustomCategoryType = '';
  private localSelectedBaseCustomCategoryRenderer: BaseCustomCategoryRenderer | null = null;
  private validationErrorFields: string[] = [];
  private allowedFileExtensions = '.pdf';
  private allowMultipleFilesUpload = false;
  private pdfMaxSize = 100000;
  private localErrorMessage = '';
  private showAddProgramProcess = false;
  private localAllPrograms: ProgramItem[] = [];
  private showMultimediaFileUploadProcess = false;
  private localAllEvents: EventItem[] = [];
  private currentEditMultimediaItem: CustomCategoryMultimediaItem | null = null;
  private showFileUploadProcess = false;
  private currentEdit360ImageItem: CustomCategoryMultimediaItem | null = null;
  private showExternalContentFileUploadProcess = false;
  private isUrlToContentDisabled = false;
  private messages: string[] = [];
  private showCloseButton = true;
  private showSectionModalPopup = '';
  private modalPopupShowCancelButton = true;
  private showSharedEventsLink = false;
  private showShareWithOtherEventsProcess = false;
  private modalPopupCancelButtonText = '';
  private modalPopupConfirmButtonText = '';
  private customCategoryItemTobeSaved: CustomCategoryItem = CustomCategoryItem.createEmpty();
  private storeHelper: StoreHelper = new StoreHelper(Store);
  private appInfo: AppRequestInfo = this.storeHelper.GetRequestInfoFromStore();
  private ShowAddButton = false;
  private EnableLinkParticipantToInformation = false;
  private hasSelectedGroups = false;
  private subHeadingString = '';
  private searchListing?: SearchItem[]| [];
  private searchLinkedListing?: SearchItem[]| [];
  private searchPlaceholder = '';
  private searchTitle = '';
  private searchNoticeLineText = '';
  private isMultipleParticipant = false;
  private participantGetListCount = 250;
  private participantsCount = 0;
  private selectedCustomerTypeId = 0;
  private noParticipantFoundText = '';
  private hasParticipant = false;
  private showSearchModule = false;
  private localAllParticipants: LinkedParticipantsItem[] = [];
  private selectedParticipantGroups: ParticipantGroups[] = [];
  private isEnableFeaturedImage= false;
  private selectedItem?: VodEntityItemModel = VodEntityItemModel.createEmpty();
  private selectedVodRenderer: VodBaseRenderModel = VodBaseRenderModel.createEmpty();
  private showAddVideo = false;
  private fileUploadGetAssetPreUploadUrl = '/CustomCategory/GetAssetObjectPreUploadByEntityType';
  private percantageVideoUploaded = 0;
  private showPreRecordedAnalytics = false;
  private showToolTip = false;
  private isSharedVideoMessageShow = false;
  private showInformation = false;
  private vonageThresholdLimitReached = false;
  private selectedItemsIds: number[] = [];
  private entityType = ModuleEnum.Information;
  private subModuleName = '';
  private isRequired = true;
  private showFullImageOption = false;
  // variables for validation
  private state = reactive({
    localCustomCategoryItem: null as CustomCategoryItem | null,
  });

  private rules = {
    localCustomCategoryItem: {
      Title: {
        required,
        minLength: minLength(1),
      },
    },
  };
  private status(validation: { $error: any; $dirty: any }) {
    return {
      error: validation.$error,
      dirty: validation.$dirty,
    };
  }
  private v$ = useVuelidate(this.rules, this.state);

  private mounted() {
    this.subHeadingString = this.$t('Program.ListOfParticipantGroups.ApplyToSelectedParticipantGroupsInformationSubHeading');
    this.virtualMeetingTypes = VirtualMeetingType.GetVirtualMeetingTypes(this.isEnableVimeoVideos());
    this.localselectedMeetingOption = this.virtualMeetingTypes.find((element) => element.Key === VodTypeEnum[1].toString())!.ParentType;
    this.CustomCategoryTypes = new CustomCategoryTypes(
      Root,
      Store,
      this.$t,
    );
    // assign selectedCustomCategoryItem object to localCustomCategoryItem object
    this.localCustomCategoryItem = Object.assign({},
      this.selectedCustomCategoryItem,
    );
    this.state.localCustomCategoryItem = this.localCustomCategoryItem;
    this.setSelectedType(this.localCustomCategoryItem);
    // Set the selected item value to VOD section
    this.SetVodDetail();
    this.isSharedVideoMessageShow = this.selectedItem!.VodDetail === undefined ? false : true;
    // assigning folder object in local object
    const newItem = CustomCategoryFolder.createEmpty();
    this.defaultFolder = newItem;
    if (this.localCustomCategoryItem.CategoryFolder == null) {
      this.localCustomCategoryItem.CategoryFolder = newItem;
    }
    // assign selected base class object props to local
    this.localSelectedBaseCustomCategoryRenderer = this.selectedBaseCustomCategoryRenderer!;
    this.IsContentUrlRequired();
    this.showFullImageOption = this.CheckIfInformationTypeIsSponsor();
    // check for mobile view or not
    if (window.innerWidth <= 767) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }

    // assign events to local variable
    if (this.availableEvents != null) {
      this.localAllEvents = this.availableEvents;
    }
    // #region multimedia emits
    // assign selected multimedia to object
    Root.on(
      'customCategory-multimedia-item-edit-operation',
      (multimedia: any) => {
        this.CustomCategoryMultimediaItemEditedOperation(multimedia as CustomCategoryMultimediaItem);
      },
    );
    // make image process component false if other custom Category item selected
    Root.on('show-image-process', (imageProcess: any) => {
      this.showImageProcess = imageProcess;
      this.showMultimediaFileUploadProcess = false;
    });
    Root.on('hide-ShareWithOtherEventsProcess', () => {
      this.showShareWithOtherEventsProcess = false;
      this.showSearchModule = false;
    });
    Root.on('hide-prerecorded-analytics', () => {
      this.showPreRecordedAnalytics = false;
    });
    Root.on('reload-customCategory-refresh', () => {
      this.showAddVideo = false;
    });
    Root.on('reload-VodDetail-delete', () => {
      Root.emit('reload-customCategory-refresh');
    });
    Root.on(
      'customCategory-multimedia-update-signalr',
      (multimediaItemData: any) => {
        this.SendNotificationRequestForCustomCategoryDetails(
          multimediaItemData,
        );
      },
    );
    Root.on(
      'linked-category-add-participant-notification',
      (customCategory: any) => {
        if (this.localCustomCategoryItem!.CustomCategoryId !== 0) {
          this.SendNotificationRequestForCustomCategoryDetails(
            customCategory,
          );
        }
        if(this.localCustomCategoryItem !== null && this.localCustomCategoryItem !== undefined && this.localCustomCategoryItem.LinkedParticipantsItems !== null && this.localCustomCategoryItem.LinkedParticipantsItems !== undefined && customCategory.dataItem.LinkedParticipantsItems.length > 0 && this.localCustomCategoryItem.ContentTypeV2 ===  CustomCategoryTypeEnum[CustomCategoryTypeEnum.Speaker].toString()) {
          this.localCustomCategoryItem.Company = this.localCustomCategoryItem.Company === null || this.localCustomCategoryItem.Company === undefined ? customCategory.dataItem.LinkedParticipantsItems[0].Company : this.localCustomCategoryItem.Company;
          this.localCustomCategoryItem.JobTitle = this.localCustomCategoryItem.JobTitle === null || this.localCustomCategoryItem.JobTitle === undefined ?  customCategory.dataItem.LinkedParticipantsItems[0].Title : this.localCustomCategoryItem.JobTitle;
        }
      },
    );
    Root.on(
      'customCategory-multimedia-item-delete-operation',
      (multimediaId: any) => {
        this.CustomCategoryMultimediaItemDeletedOperation(multimediaId as number);
      },
    );
    // #endregion
    Root.on(
      'showSharedEventsLink',
      () => {
        this.showSharedEventsLink = true;
      },
    );

    Root.on(
      'searchItem-search-fromsearchcomponent',
      (searchString: any) => {
        this.SearchApiCall(searchString as string, [], '', '', true);
      });

    Root.on(
      'change-visibility-search',
      (item: ChangeVisibilitySearch) => {
        const visibilitySearch = item ;
        this.SearchApiCall(visibilitySearch.SearchString, visibilitySearch.SearchLinkedListing, visibilitySearch.SearchPlaceholder, visibilitySearch.SearchTitle, false);
      });
    Root.on('sendListOfParticipantGroupsInLocalItem', (selectedParticipantGroups: any) => {
      this.localCustomCategoryItem!.ParticipantGroups = selectedParticipantGroups;
      this.selectedParticipantGroups = selectedParticipantGroups;
    });
    Root.on(
      'linkedParticipantsData',
      (participants: LinkedParticipantsItem[]) => {
        this.localAllParticipants = [];
        (participants ).forEach((element) => {
          this.localAllParticipants.push(element);
        });
        this.localAllParticipants.sort((value) =>
          value.Name ? -1 : 1, // `true` values first
        );
      },
    );
    Root.on(
      'VODCustomCategoryData',
      (val: VodEntityItemModel) => {
        const vodEntityItemModel = val ;
        this.selectedItem!.VirtualMeetingType = VodTypeEnum[1].toString();
        this.selectedItem!.Id = vodEntityItemModel.Id;
        this.selectedItem!.Title = vodEntityItemModel.Title;
        this.selectedItem!.VodDetail = vodEntityItemModel.VodDetail!;
      },
    );
    Root.on('show-add-video-section-byEntity', (addVideo: boolean) => {
      Root.on('show-add-video-section-vod-byEntity', (addVideoCallFrom: number) => {
        this.localselectedMeetingOption = addVideoCallFrom ;
      });
      this.showAddVideo = addVideo ;
      this.isShowButtons = false;
    });
    Root.on('vod-save-current-progress', (percentageuploaded: number) => {
      this.selectedVodRenderer.PercentageVideoUploaded = percentageuploaded ;
    });
    Root.on('show-pre-recorded-video-analytics-section', (showChart: boolean) => {
      this.showPreRecordedAnalytics = showChart ;
      setTimeout(() => {
        Root.emit('customCategoryDetailLoadingVod', false);
      }, 1500);
    });
    // set EnableLinkParticipantToInformation flag value in local variable for show or hide ListOfParticipantGroups coponents and feature image
    this.EnableLinkParticipantToInformation = this.appInfo.EnableLinkParticipantToInformation;
    this.isEnableFeaturedImage = !this.EnableLinkParticipantToInformation;
    if(this.appInfo.VerifyThresholdLimit){
      if(!this.appInfo.IsThresholdLimitReached) {
        this.VerifyThresholdLimit();
      } else {
        this.vonageThresholdLimitReached = true;
      }
    }
  }
  private async VerifyThresholdLimit() {
    this.vonageThresholdLimitReached = await Common.prototype.VerifyThresholdLimit();
  }
  // Check if event web page premium feature enabled or not
  private IsEventWebpagePFEnabled() {
    return this.appInfo.EnableEventWebPage && this.appInfo.IsEventWebPagePFActive;
  }
  private IsInformatiomTypeSpeaker() {
    if(this.localSelectedBaseCustomCategoryRenderer!== null && this.localSelectedBaseCustomCategoryRenderer!== undefined) {
      return this.localSelectedBaseCustomCategoryRenderer.CustomCategoryTypeId === CustomCategoryTypeEnum.Speaker;
    }
  }
  private IsEventWebpagePFEnabledAndInformatiomTypeSponsorOrSpeaker() {
    if(this.localSelectedBaseCustomCategoryRenderer!== null && this.localSelectedBaseCustomCategoryRenderer!== undefined) {
      return (this.localSelectedBaseCustomCategoryRenderer.CustomCategoryTypeId === CustomCategoryTypeEnum.Sponsor || this.localSelectedBaseCustomCategoryRenderer.CustomCategoryTypeId === CustomCategoryTypeEnum.Speaker) && this.IsEventWebpagePFEnabled();
    }
  }
  private CheckIfInformationTypeIsSponsor() {
    if(this.localSelectedBaseCustomCategoryRenderer!== null && this.localSelectedBaseCustomCategoryRenderer!== undefined && this.localSelectedBaseCustomCategoryRenderer.CustomCategoryTypeId === CustomCategoryTypeEnum.Sponsor) {
      return true;
    } else {
      return false;
    }
  }
  private GetEventWebpageCheckboxLabelText() {
    return this.$t('DoNotDisplayItemOnEventWebpage',{item: this.selCustomCategoryType.toLowerCase()});
  }
  private SetEventWebpageCheckboxValue(val: boolean) {
    this.localCustomCategoryItem!.HideFromEventWebpage = val;
  }
  private IsContentUrlRequired() {
    if(this.localSelectedBaseCustomCategoryRenderer!== null && this.localSelectedBaseCustomCategoryRenderer!== undefined && this.localSelectedBaseCustomCategoryRenderer.CustomCategoryTypeId === CustomCategoryTypeEnum.Sponsor) {
      this.isRequired = false;
    }
  }
  private vodsaveupdate() {
    this.localCustomCategoryItem!.IsVodDetail = true;
  }
  // GetVodDetailValues to send to videoondemand and mus video component
  private GetVodDetailValues(): VodVideoMetricsRequest {
    const ObjVodVideoMetricsRequest: VodVideoMetricsRequest = new VodVideoMetricsRequest();
    ObjVodVideoMetricsRequest.VideoId = this.localCustomCategoryItem!.VodDetail!.VodVideoDetail!.Id;
    ObjVodVideoMetricsRequest.VideoDetailId = this.localCustomCategoryItem!.VodDetail!.Id;

    ObjVodVideoMetricsRequest.ApplicationId =  this.localCustomCategoryItem!.VodDetail!.VodDetailApplicationInstance!.ApplicationId;

    ObjVodVideoMetricsRequest.ApplicationInstanceId = this.localCustomCategoryItem!.VodDetail!.VodDetailApplicationInstance!.EventId;
    ObjVodVideoMetricsRequest.EntityId = this.localCustomCategoryItem!.VodDetail!.VodDetailApplicationInstance!.EntityId;
    ObjVodVideoMetricsRequest.EntityType = 'CustomCategory';
    return ObjVodVideoMetricsRequest;
  }
  // ShowAddVideoSection
  private ShowAddVideoSection() {
    if (this.localCustomCategoryItem !== null && this.localCustomCategoryItem !== undefined) {
      return(
        this.localCustomCategoryItem.CustomCategoryId > 0 &&
        this.showAddVideo &&
         !this.showShareWithOtherEventsProcess &&
         !this.showAddProgramProcess &&
         !this.showFileUploadProcess &&
         !this.showMultimediaFileUploadProcess &&
         !this.showImageProcess &&
         !this.showExternalContentFileUploadProcess &&
         !this.showPreRecordedAnalytics);
    }
  }
  // ShowPreRecordedVideoChartSection
  private ShowPreRecordedVideoChartSection() {
    if (this.localCustomCategoryItem !== null && this.localCustomCategoryItem !== undefined) {
      return(
        this.localCustomCategoryItem.CustomCategoryId > 0 &&
      this.showPreRecordedAnalytics &&
      !this.showShareWithOtherEventsProcess &&
         !this.showAddProgramProcess &&
         !this.showFileUploadProcess &&
         !this.showMultimediaFileUploadProcess &&
         !this.showImageProcess &&
         !this.showExternalContentFileUploadProcess);
    }
  }
  // Ckeck is Enable VimeoVideos
  private isEnableVimeoVideos(): boolean {
    if (this.appInfo.EnableVimeoVideos) {
      return true;
    }
    return false;
  }
  // GetCaptionFilesAcceptedTypesForVOD
  private GetCaptionFilesAcceptedTypesForVOD() {
    return 'vtt|srt';
  }
  // SetVodDetail
  private SetVodDetail() {
    this.selectedItem!.VirtualMeetingType = VodTypeEnum[1].toString();
    this.selectedItem!.Id = this.localCustomCategoryItem!.CustomCategoryId;
    this.selectedItem!.Title = this.localCustomCategoryItem!.Title;
    this.selectedItem!.VodDetail = this.localCustomCategoryItem!.VodDetail!;
    this.selectedVodRenderer =  VodService.GetEntityObjectByType(0, this.GetVodDetailObject(), this.selectedItem!.Id, new Date(),this.fileUploadGetAssetPreUploadUrl,this.GetAcceptedVideoFilesForVod(),this.GetCaptionFilesAcceptedTypesForVOD(),VodTypeEnum.Mux);
    Root.emit('voddetail-updated',this.selectedVodRenderer);
  }
  // GetButtonText
  private GetButtonText() {
    if(this.localSelectedBaseCustomCategoryRenderer!.CustomCategoryType.toLowerCase() === CustomCategoryTypeEnum[CustomCategoryTypeEnum.Sponsor].toString().toLowerCase()) {
      return this.$t('CustomCategory.AddButton', {type: this.$t('Representative').toString()});
    } else {
      return this.$t('CustomCategory.AddButton', {type: this.localSelectedBaseCustomCategoryRenderer!.CustomCategoryType.toLowerCase()});
    }
  }
  // GetAcceptedVideoFilesForVod
  private GetAcceptedVideoFilesForVod() {
    return 'mp4|webM|3gp|mpeg|ogg|mov|wmv|flv|avchd|mkv|avi';
  }
  // GetVodDetailObject
  private GetVodDetailObject() {
    if (this.selectedItem!.VodDetail === null || this.selectedItem!.VodDetail === undefined) {
      return VodDetail.createEmpty();
    } else {
      return this.selectedItem!.VodDetail;
    }
  }
  // SetVodDetailId
  private SetVodDetailId(event: any) {
    if (this.selectedItem!.VodDetail === undefined) {
      this.selectedItem!.VodDetail = VodDetail.createEmpty();
    }
    this.selectedItem!.VodDetail = event;
  }
  // close commmon search component
  private ClickClose() {
    this.isShowCustomCategoryEditDetailView = true;
    this.showSearchModule = false;
    setTimeout(() => {
      Root.emit(
        'selected-participant-group-is',
        this.localCustomCategoryItem!.ParticipantGroupIds,
      );
    }, 200);
    setTimeout(() => {
      Root.emit('customCategoryDetailLoadingVod', false);
    }, 100);
  }
  private Submit(val: any) {
    // assign contentType value based on selected customCategory type
    if (this.localCustomCategoryItem!.ContentTypeV2 === CustomCategoryTypeEnum[CustomCategoryTypeEnum.OtherInformation].toString()||
        (this.localCustomCategoryItem!.ContentTypeV2 === CustomCategoryTypeEnum[CustomCategoryTypeEnum.Sponsor].toString()) ||
        this.localCustomCategoryItem!.ContentTypeV2 === CustomCategoryTypeEnum[CustomCategoryTypeEnum.Speaker].toString()) {
      this.localCustomCategoryItem!.ContentType = 'Normal';
    } else {
      this.localCustomCategoryItem!.ContentType = 'ExternalUrl';
    }
    this.localCustomCategoryItem!.ParticipantGroups = this.selectedParticipantGroups;
    // validations
    val.$touch();
    this.visibleValidationSummary = false;
    this.validationErrorFields = [];
    if (this.localCustomCategoryItem!.Title === '' || this.localCustomCategoryItem!.Title === undefined) {
      if (this.localCustomCategoryItem!.ContentTypeV2 === CustomCategoryTypeEnum[CustomCategoryTypeEnum.Speaker].toString() ||
          this.localCustomCategoryItem!.ContentTypeV2 === CustomCategoryTypeEnum[CustomCategoryTypeEnum.Sponsor].toString() ||
          this.localCustomCategoryItem!.ContentTypeV2 === CustomCategoryTypeEnum[CustomCategoryTypeEnum.SocialMedia].toString()) {
        this.validationErrorFields.push(
          this.$t('Information.Input.Name').toString(),
        );
      } else {
        this.validationErrorFields.push(
          this.$t('Title').toString(),
        );
      }
    }
    if (!this.isValidURL && this.localSelectedBaseCustomCategoryRenderer!.ShowURL) {
      this.validationErrorFields.push(
        this.$t('CustomCategory.InvalidUrl').toString(),
      );
    }
    if (this.hasSelectedGroups === true && this.localCustomCategoryItem!.ParticipantGroups.length <= 0) {
      this.validationErrorFields = [];
      this.validationErrorFields.push(
        this.$t('Program.NoParticipantGroupSelected').toString(),
      );
      this.visibleValidationSummary = true;
      return;
    }
    if (!this.isValidFileURL && this.localSelectedBaseCustomCategoryRenderer!.ShowFileUpload) {
      if (this.localErrorMessage !== '' && this.localErrorMessage !== undefined) {
        this.validationErrorFields.push(
          this.localErrorMessage,
        );
      } else {
        this.validationErrorFields.push(
          this.$t('FileUpload.Upload.ErrorChoosePdfOnly').toString(),
        );
      }
    }
    if (this.validationErrorFields.length > 0) {
      this.visibleValidationSummary = true;
      return;
    }
    val.$reset();
    this.visibleValidationSummary = false;
    // No errors submitting.
    this.FillCustomCategoryItemTobeSaved();
    const objForJson = {
      Latitude: this.customCategoryItemTobeSaved.Latitude,
      Longitude: this.customCategoryItemTobeSaved.Longitude,
    };
    const signalObject = this.SendNotificationRequest(false);
    const self = this;
    FetchData('/CustomCategory/EditCustomCategory', JSON.stringify({
      customCategoryModel: this.customCategoryItemTobeSaved,
      jsonData: JSON.stringify(objForJson),
    }))
      .then((response: { ValidationErrors: string|any[]; CustomCategory: CustomCategoryItem; Folders: any }) => {
        if (response.ValidationErrors.length > 0) {
          // Server side validation failuere
          alert(this.$t(response.ValidationErrors[0]).toString());
        } else {
          // Successful update / save
          response.CustomCategory.IconUrl = self.customCategoryItemTobeSaved.IconUrl;
          response.CustomCategory.MainMediaFullUrl = self.customCategoryItemTobeSaved.MainMediaFullUrl;
          // clear controls after submit the page.
          if (this.customCategoryItemTobeSaved.CustomCategoryId === 0) {
            Root.emit('clearDescription', '');
          }
          Root.emit('updatedFolders', response.Folders);
          this.SendNotificationRequestToQueue(
            signalObject,
            response.CustomCategory,
          );
        }
        setTimeout(() => {
          this.ClearMessagesList();
        }, 5000);
      })
      .catch(() => {
        this.showLoading = false;
      });
  }
  // Created new variable to save program item and lighten the object.
  // removed additional array items which don't need while saving program item
  private FillCustomCategoryItemTobeSaved() {
    Object.assign(
      this.customCategoryItemTobeSaved,
      this.localCustomCategoryItem,
    );
    this.customCategoryItemTobeSaved.CustomCategoryMedias = [];
    this.customCategoryItemTobeSaved.CustomCategoryMedias360 = [];
    this.customCategoryItemTobeSaved.LinkedPrograms = [];
  }
  // Clear Messages List
  private ClearMessagesList() {
    this.messages = [];
  }
  private Cancel(val: any) {
    if (this.localCustomCategoryItem!.CustomCategoryId > 0) {
      val.$reset();
      const item = this.selectedCustomCategoryItem;
      this.$emit('custom-category-item-cancel-operation', item);
      // clear all checkboxes in information list
      Root.emit('clearCustomCategoryCheckboxes', []);
    } else {
      Root.emit('customCategory-cancel-clicked');
    }
  }

  // popUp click when delete a information
  private ConfirmClick(confirmed: boolean) {
    this.modalPopupVisible = false;
    if (confirmed) {
      if (
        this.showSectionModalPopup === this.NotificationCustomCategoryDeleted
      ) {
        this.DeleteCustomcategory();
      }
    } else {
      return;
    }
  }

  // emit customCategoryTypeId when switch type from drop down
  private OnCustomcategoryTypeChange(event: any) {
    const typeId = Number(event.target.value);
    this.$emit('CustomCategoryType', typeId);
    this.selCustomCategoryType = CustomCategoryTypeEnum[typeId].toString();
  }

  // #region imageComponent methods
  // all methods in this region link with image process components
  private clickOnImage(val: boolean) {
    this.isShowButtons = val;
    this.showImageProcess = val;
    this.isShowCustomCategoryEditDetailView = !val;
    this.subModuleName = SubModulePrefix.IconImage;
    setTimeout(() => {
      Root.emit(
        'selected-participant-group-is',
        this.localCustomCategoryItem!.ParticipantGroupIds,
      );
    }, 200);
  }
  // GetImageUrl
  private GetImageUrl() {
    if (this.localCustomCategoryItem!.IconUrl !== null && this.localCustomCategoryItem!.IconUrl !== undefined) {
      return this.localCustomCategoryItem!.IconUrl === '' ?
        this.missingImage :
        this.localCustomCategoryItem!.IconUrl;
    } else {
      return this.missingImage;
    }
  }
  // showImageProcessComp
  private showImageProcessComp(){
    return this.showImageProcess;
  }
  // GetImageFullUrl
  private GetImageFullUrl() {
    if (
      this.localCustomCategoryItem!.MainMediaFullUrl === '' ||
         this.localCustomCategoryItem!.MainMediaFullUrl === null
    ) {
      return this.missingImage;
    } else {
      return this.localCustomCategoryItem!.MainMediaFullUrl;
    }
  }
  private CheckIsFullImage(val: boolean) {
    if(this.localCustomCategoryItem !== null && this.localCustomCategoryItem !== undefined) {
      this.localCustomCategoryItem.IsFullImage = val;
    }
  }
  // SetImageIconUrl
  private SetImageIconUrl(imgurl: string, mainMediaFullUrl: string) {
    this.localCustomCategoryItem!.IconUrl = imgurl;
    this.localCustomCategoryItem!.MainMediaFullUrl = mainMediaFullUrl;
  }
  // SetFeaturedImageIconUrl
  private SetFeaturedImageIconUrl(
    thumbUrl: string,
    fullUrl: string,
    height: number,
    width: number,
    top: string,
    left: string,
  ) {
    this.localCustomCategoryItem!.FeaturedImageUrl = thumbUrl;
    this.localCustomCategoryItem!.FeaturedOrginalImageUrl = fullUrl;
    this.localCustomCategoryItem!.FeaturedImageWidth = width;
    this.localCustomCategoryItem!.FeaturedImageHeight = height;
    this.localCustomCategoryItem!.TopPosition = top;
    this.localCustomCategoryItem!.LeftPosition = left;
  }
  // ShowInformationtitleBelowFeaturedImage
  private ShowInformationtitleBelowFeaturedImage(
    isShowTitleBelowFeaturedImage: boolean,
  ) {
    this.localCustomCategoryItem!.ShowFeatureImageBelowTitle = isShowTitleBelowFeaturedImage;
  }
  // ShowFileUploadSection
  private ShowFileUploadSection() {
    if (this.localCustomCategoryItem !== null && this.localCustomCategoryItem !== undefined) {
      if(!this.showImageProcess) {
        this.subModuleName = SubModulePrefix.Multimedia;
      }
      return (
        (this.localCustomCategoryItem.CustomCategoryId > 0 &&
         (this.showMultimediaFileUploadProcess || this.showFileUploadProcess)
        ) || this.showExternalContentFileUploadProcess);
    }
  }
  // GetfeaturedimageFullUrl
  private GetfeaturedimageFullUrl() {
    if (this.localCustomCategoryItem !== null) {
      if (this.localCustomCategoryItem.FeaturedOrginalImageUrl == null || this.localCustomCategoryItem.FeaturedOrginalImageUrl === '') {
        this.localCustomCategoryItem.FeaturedImageWidth = this.defaultImageWidth;
        this.localCustomCategoryItem.FeaturedImageHeight = this.defaultImageHeight;
        return this.featureMissingImage;
      } else {
        return this.localCustomCategoryItem.FeaturedOrginalImageUrl;
      }
    }
  }
  // GetFeaturedImageUrl
  private GetFeaturedImageUrl() {
    if (this.localCustomCategoryItem !== null) {
      return (this.localCustomCategoryItem.FeaturedOrginalImageUrl === undefined || this.localCustomCategoryItem.FeaturedImageUrl === '' || this.localCustomCategoryItem.FeaturedImageUrl === null) ?
        this.featureMissingImage :
        this.localCustomCategoryItem.FeaturedImageUrl;
    }
  }
  // #endregion
  // check type of content based on this show or hide feature image
  private IsExternalInformationType() {
    if (this.localSelectedBaseCustomCategoryRenderer!.CustomCategoryType === CustomCategoryTypeEnum[CustomCategoryTypeEnum.OtherInformation].toString() ||
      this.localSelectedBaseCustomCategoryRenderer!.CustomCategoryType === CustomCategoryTypeEnum[CustomCategoryTypeEnum.Sponsor].toString() ||
      this.localSelectedBaseCustomCategoryRenderer!.CustomCategoryType === CustomCategoryTypeEnum[CustomCategoryTypeEnum.Speaker].toString()) {
      this.localCustomCategoryItem!.ContentType = 'Normal';
    } else {
      this.localCustomCategoryItem!.ContentType = 'ExternalUrl';
    }
    if (this.localCustomCategoryItem !== null) {
      return this.localCustomCategoryItem.ContentType === 'ExternalUrl' ? true : false;
    } else {
      return false;
    }
  }


  // #region multimedia section methods
  // all method in this region  link with file upload component and this component use in multimedia image upload
  private GetMultimediaFileUploadHeadingTitle() {
    if (this.showMultimediaFileUploadProcess === true) {
      return this.$t('Multimedia.Add');
    }
  }
  // GetMultimediaImageUrl
  private GetMultimediaImageUrl() {
    if (this.showMultimediaFileUploadProcess === true) {
      if (this.currentEditMultimediaItem != null) {
        if (this.currentEditMultimediaItem.ThumbUrl !== '') {
          if (this.currentEditMultimediaItem.Type === this.video) {
            return this.missingVideo;
          } else {
            return this.currentEditMultimediaItem.ThumbUrl;
          }
        }
      }
    } else if (this.showFileUploadProcess === true) {
      return this.currentEdit360ImageItem != null ?
        this.currentEdit360ImageItem.ThumbUrl !== '' ?
          this.currentEdit360ImageItem.ThumbUrl :
          this.missingImage :
        this.missingImage;
    }
    return this.missingImage;
  }
  // GetMultimediaImageFullUrl
  private GetMultimediaImageFullUrl() {
    if (this.showMultimediaFileUploadProcess === true) {
      if (this.currentEditMultimediaItem != null) {
        if (this.currentEditMultimediaItem.Url !== '') {
          if (this.currentEditMultimediaItem.Type === this.video) {
            return this.missingVideo;
          } else {
            return this.currentEditMultimediaItem.Url;
          }
        }
      }
    } else if (this.showFileUploadProcess === true) {
      return this.currentEdit360ImageItem != null ?
        this.currentEdit360ImageItem.Url !== '' ?
          this.currentEdit360ImageItem.Url :
          this.missingImage :
        this.missingImage;
    }

    return this.missingImage;
  }
  // GetAcceptedFileTypes
  private GetAcceptedFileTypes() {
    const acceptedFileTypes: string[] = [];
    if (this.showMultimediaFileUploadProcess === true) {
      acceptedFileTypes.push('image/png');
      acceptedFileTypes.push('image/x-png');
      acceptedFileTypes.push('image/jpg');
      acceptedFileTypes.push('image/jpeg');
      acceptedFileTypes.push('video.*');
    } else if (this.showFileUploadProcess === true) {
      acceptedFileTypes.push('image/png');
      acceptedFileTypes.push('image/x-png');
      acceptedFileTypes.push('image/jpg');
      acceptedFileTypes.push('image/jpeg');
    } else if (this.showExternalContentFileUploadProcess === true) {
      acceptedFileTypes.push('application/pdf');
    }
    return acceptedFileTypes;
  }
  // GetMultimediaFileUploadSubHeadingTitle
  private GetMultimediaFileUploadSubHeadingTitle() {
    if (this.showMultimediaFileUploadProcess === true) {
      return this.$t('FileUpload.lblSelectMultimedia');
    } else if (this.showFileUploadProcess === true) {
      return this.$t('FileUpload.lblSelect360Image');
    } else if (this.showExternalContentFileUploadProcess === true) {
      return this.$t('FileUpload.lblSelectPdfFile');
    }
  }
  // GetFileExtensions
  private GetFileExtensions() {
    if (this.showMultimediaFileUploadProcess === true) {
      return 'png,.jpg,.jpeg,.mp4';
    } else if (this.showFileUploadProcess === true) {
      return '.png,.jpg,.jpeg';
    } else if (this.showExternalContentFileUploadProcess === true) {
      return '.pdf';
    }
  }
  // GetValidationType
  private GetValidationType() {
    if (this.showMultimediaFileUploadProcess === true) {
      return 'lessthan';
    } else if (this.showFileUploadProcess === true) {
      return 'greaterthan';
    } else if (this.showExternalContentFileUploadProcess === true) {
      return 'lessthan';
    }
  }
  // GetIconImageSize
  private GetIconImageSize() {
    if (this.showMultimediaFileUploadProcess === true) {
      return 150;
    } else if (this.showFileUploadProcess === true) {
      return 4096;
    } else if (this.showExternalContentFileUploadProcess === true) {
      return 150;
    }
  }
  // GetValidationMessage
  private GetValidationMessage() {
    if (this.showMultimediaFileUploadProcess === true) {
      return 'FileUpload.Upload.ErrorChooseImageOrVideoOnly';
    } else if (this.showFileUploadProcess === true) {
      return 'FileUpload.Upload.ErrorChooseImageOnly';
    } else if (this.showExternalContentFileUploadProcess === true) {
      return 'FileUpload.Upload.ErrorChoosePdfOnly';
    }
  }
  // showThreshHold
  private showThreshHold(val: any) {
    this.vonageThresholdLimitReached = val;
    // eslint-disable-next-line no-console
    console.log(val);
    // Do nothing for information
  }
  // GetMultimediaImageType
  private GetMultimediaImageType() {
    if (this.showMultimediaFileUploadProcess === true) {
      if (this.currentEditMultimediaItem != null) {
        return this.currentEditMultimediaItem.IsVideo === true ? 'Video' : this.currentEditMultimediaItem.Type;
      }
    } else if (this.showFileUploadProcess === true) {
      return this.currentEdit360ImageItem != null ? this.currentEdit360ImageItem.Type : '';
    } else if (this.showExternalContentFileUploadProcess === true) {
      return 'PDF';
    }
    return '';
  }
  // CustomCategoryMultimediaItemFileUploadCompleted
  private CustomCategoryMultimediaItemFileUploadCompleted() {
    if (this.showMultimediaFileUploadProcess === true) {
      this.showMultimediaFileUploadProcess = false;
      setTimeout(() => {
        Root.emit(
          'customCategory-multimedia-item-imageupdated-operation',
          {
            selectedMultimediaitem: this.currentEditMultimediaItem,
          },
        );
      }, 100);
    } else if (this.showFileUploadProcess === true) {
      this.showFileUploadProcess = false;
      setTimeout(() => {
        Root.emit(
          'customCategory-360image-item-imageupdated-operation',
          {
            selected360Imageitem: this.currentEdit360ImageItem,
          },
        );
      }, 100);
    } else if (this.showExternalContentFileUploadProcess === true) {
      this.showExternalContentFileUploadProcess = false;
      this.ExternalInformationTypeChangeFileUpload('PDF');
    }
    setTimeout(() => {
      Root.emit(
        'selected-participant-group-is',
        this.localCustomCategoryItem!.ParticipantGroupIds,
      );
    }, 200);
  }
  // SetMultimediaIconUrl
  private SetMultimediaIconUrl(
    imgurl: string,
    mainMediaFullUrl: string,
    type: string,
  ) {
    if (this.showMultimediaFileUploadProcess === true) {
      this.currentEditMultimediaItem!.ThumbUrl = imgurl;
      this.currentEditMultimediaItem!.Url = mainMediaFullUrl;
      // image croppie doesn't return media type.
      if (type != null) {
        this.currentEditMultimediaItem!.Type = type;
      }
    } else if (this.showFileUploadProcess === true) {
      this.currentEdit360ImageItem!.ThumbUrl = imgurl;
      this.currentEdit360ImageItem!.Url = mainMediaFullUrl;
      this.currentEdit360ImageItem!.Type = this.imageType360Item;
    } else if (this.showExternalContentFileUploadProcess === true) {
      if (mainMediaFullUrl !== null && mainMediaFullUrl !== '') {
        this.localCustomCategoryItem!.ContentUrl = mainMediaFullUrl;
      } else  {
        this.localCustomCategoryItem!.ContentUrl = this.selectedCustomCategoryItem!.ContentUrl;
      }
    }
  }
  // GetPreviousIconUrl
  private GetPreviousIconUrl(
    imgUrl: string,
    mainMediaFullUrl: string,
    type: string,
  ) {
    if (this.showMultimediaFileUploadProcess === true) {
      this.currentEditMultimediaItem!.OldThumbUrl = imgUrl;
      this.currentEditMultimediaItem!.OldUrl = mainMediaFullUrl;
      this.currentEditMultimediaItem!.Type = type;
    } else if (this.showFileUploadProcess === true) {
      this.currentEdit360ImageItem!.OldThumbUrl = imgUrl;
      this.currentEdit360ImageItem!.OldUrl = mainMediaFullUrl;
      this.currentEdit360ImageItem!.Type = this.imageType360Item;
    }
  }
  // ExternalInformationTypeChangeFileUpload
  private ExternalInformationTypeChangeFileUpload(val: any) {
    this.localCustomCategoryItem!.ExternalContentType = val;
    this.isUrlToContentDisabled = val === 'PDF' ? true : false;
  }

  // #endregion multimedia

  // #region get and set & show and hide elements and components based on object (selected type class object)
  private GetTitle() {
    return this.localSelectedBaseCustomCategoryRenderer!.UrlText;
  }
  // Use to get url and pass to component
  private GetUrl() {
    return this.localCustomCategoryItem!.ContentUrl;
  }
  private ShowFeatureImage() {
    return !this.IsExternalInformationType() && !this.EnableLinkParticipantToInformation;
  }
  private GetImageProcessHeadingTitle(): string {
    return !this.IsExternalInformationType() && !this.EnableLinkParticipantToInformation ? this.$t('ImageProcess.headingTitle').toString() : this.$t('ImageProcess.iconImage').toString();
  }

  // #region show or hide components and section on this screen based on object or type of custom category

  private CheckFeaturedImage() {
    return this.localCustomCategoryItem!.FeaturedOrginalImageUrl === '' || this.localCustomCategoryItem!.FeaturedOrginalImageUrl === null ? false : true;
  }
  private ShowAddUrl() {
    return this.localSelectedBaseCustomCategoryRenderer!.ShowURL;
  }
  private ShowFileUpload() {
    return this.localSelectedBaseCustomCategoryRenderer!.ShowFileUpload;
  }
  private ShowDescription() {
    return this.localSelectedBaseCustomCategoryRenderer!.ShowDescription;
  }
  private ShowAddress() {
    return this.localSelectedBaseCustomCategoryRenderer!.ShowAddress;
  }
  private ShowMultimediaSection() {
    return this.localSelectedBaseCustomCategoryRenderer!.ShowMultimedia;
  }

  private ShowVodSection() {
    if (this.appInfo.EnableVideoOnDemandCustomCategory && this.appInfo.AllowEnableTimeZoneFeature) {
      return this.localSelectedBaseCustomCategoryRenderer!.ShowVOD;
    }
  }
  // #endregion

  // Set the value in local variable
  private SetUrl(urlValue: string) {
    this.localCustomCategoryItem!.ContentUrl = urlValue;
  }
  // Set the value in local variable
  private IsValidUrl(isValidUrl: boolean) {
    this.isValidURL = isValidUrl;
  }
  // Set the value in local variable
  private IsValidFile(isValidFile: boolean) {
    this.isValidFileURL = isValidFile;
  }
  // Set the value in local variable
  private SetFileUrl(fileUrl: string) {
    this.localCustomCategoryItem!.ContentUrl = fileUrl;
  }
  // Set the value in local variable
  private SetErrorMessage(errorMessage: string) {
    this.localErrorMessage = errorMessage;
    if(errorMessage === '') {
      this.visibleValidationSummary = false;
      this.validationErrorFields = [];
    }
  }
  public get SetProfileText(): string {
    if (this.localCustomCategoryItem!.CustomCategoryId === 0) {
      this.ShowAddButton = false;
      return this.$t('CustomCategory.LinkedParticipant.CreateNewInformationItemMessage').toString();

    } else {
      this.ShowAddButton = true;
      return this.localSelectedBaseCustomCategoryRenderer!.LinkParticipantProfileNoProfileText;
    }
  }
  // Set the value in local variable
  private SetDescriptionValue(description: string) {
    if (description != null && this.localCustomCategoryItem != null) {
      if (typeof description !== 'undefined') {
        this.localCustomCategoryItem.Description = description;
      }
    }
  }

  // show or hide linked participant section or component based on object type and flag value
  private ShowCustomCategoryLinkedParticipantListView(): boolean {
    return (this.localSelectedBaseCustomCategoryRenderer!.ShowLinkedParticipantProfile && this.appInfo.EnableParticipantList);
  }
  // #endregion

  // set selected type in varaiable for show on drop down
  private setSelectedType(obj: CustomCategoryItem) {
    // creating a blnk object
    if (this.localCustomCategoryItem!.CustomCategoryId === undefined) {
      this.localCustomCategoryItem = new CustomCategoryItem();
    }
    // assign ContentTypeV2 to object
    if (this.customCategoryTypeId !== undefined) {
      if(obj.ContentTypeV2 === '') {
        this.localCustomCategoryItem!.ContentTypeV2 = CustomCategoryTypeEnum[this.customCategoryTypeId].toString();
        obj.ContentTypeV2 = this.localCustomCategoryItem!.ContentTypeV2;
      }
      if (obj.ContentTypeV2 === CustomCategoryTypeEnum[CustomCategoryTypeEnum.OtherInformation].toString() ) {
        this.selCustomCategoryType = 'Other information';
      } else if (obj.ContentTypeV2 === CustomCategoryTypeEnum[CustomCategoryTypeEnum.SocialMedia].toString()) {
        this.selCustomCategoryType = 'Social media';
      } else if (obj.ContentTypeV2 === CustomCategoryTypeEnum[CustomCategoryTypeEnum.Webpage].toString()) {
        this.selCustomCategoryType = 'Webpage';
      } else {
        this.selCustomCategoryType = obj.ContentTypeV2;
      }
    }
  }
  // show attach program with selected custom category
  private showAttachProgram(val: any) {
    Root.emit(
      'selected-participant-group-is',
      this.localCustomCategoryItem!.ParticipantGroupIds,
    );
    this.showAddProgramProcess = val.showAddPrograms;
    this.localAllPrograms = val.programs;
    this.isShowButtons = false;
    Root.emit('linkedProgramData', val.programs);
    this.scrollToElement('customCategoryLinkedProgramDiv');
    setTimeout(() => {
      Root.emit(
        'selected-participant-group-is',
        this.localCustomCategoryItem!.ParticipantGroupIds,
      );
    }, 200);
  }
  private scrollToElement(id: string) {
    setTimeout(() => {
      const el = document.getElementById(id);
      if (el) {
        el.scrollIntoView({
          block: 'nearest',
          behavior: 'smooth',
          inline: 'nearest',
        });
      }
    }, 200);
  }
  private ShowAddProgramSection() {
    // show add program section and hide another sections (show CustomCategoryAddProgram on screen)
    if (this.localCustomCategoryItem !== null && this.localCustomCategoryItem !== undefined) {
      return (
        this.localCustomCategoryItem.CustomCategoryId > 0 &&
         this.showAddProgramProcess &&
         !this.showImageProcess &&
         !this.showMultimediaFileUploadProcess &&
         !this.showFileUploadProcess &&
         !this.showExternalContentFileUploadProcess &&
         !this.showShareWithOtherEventsProcess &&
         !this.showAddVideo &&
         !this.showPreRecordedAnalytics
      );
    }
  }
  private ShowCustomCategoryEditDetailView() {
    // show child component edit section and another (show CustomCategory edit screen)
    return (
      this.localCustomCategoryItem &&
         !this.showImageProcess &&
         !this.showAddProgramProcess &&
         !this.showMultimediaFileUploadProcess &&
         !this.showFileUploadProcess &&
         !this.showExternalContentFileUploadProcess &&
         !this.showShareWithOtherEventsProcess &&
         !this.showSearchModule &&
         !this.showAddVideo &&
         !this.showPreRecordedAnalytics
    );
  }
  private ShowShareWithOtherEventsSection() {
    // show share with another event section and hide another all (show CustomCategoryShareWithOtherEvents on screen)
    if (this.localCustomCategoryItem !== null && this.localCustomCategoryItem !== undefined) {
      return (
        this.localCustomCategoryItem.CustomCategoryId > 0 &&
         this.showShareWithOtherEventsProcess &&
         !this.showAddProgramProcess &&
         !this.showFileUploadProcess &&
         !this.showMultimediaFileUploadProcess &&
         !this.showImageProcess &&
         !this.showExternalContentFileUploadProcess &&
         !this.showAddVideo &&
         !this.showPreRecordedAnalytics
      );
    }
  }
  // Send Notification Request For Custom Category Details
  private SendNotificationRequestForCustomCategoryDetails(
    customCategoryDetailItem: any,
  ) {
    const signalObject = this.SendNotificationRequest(false, true);
    const signalR: CustomCategorySignalRModel = new CustomCategorySignalRModel(
      Root,
      Store,
      this.$t,
    );
    customCategoryDetailItem.Title = this.selectedCustomCategoryItem!.Title;
    signalObject.JSONData = signalR.GetCustomCategoryDetailItemToSendToSignalR(
      customCategoryDetailItem,
    );
    signalR.PerformCustomCategorySingleOperation(signalObject, true);
  }
  // Send Notification Request
  private SendNotificationRequest(
    isDelete: boolean,
    isCustomCategoryDetails?: boolean,
  ): SignalRModelRequest {
    setTimeout(() => {
      Root.emit('show-notification-popup', true);
      Root.emit('show-notification-loading', true);
    }, 100);
    Root.emit('customCategory-uncheck-select-all', true);
    const signalR: CustomCategorySignalRModel = new CustomCategorySignalRModel(
      Root,
      Store,
      this.$t,
    );
    let signalObject: SignalRModelRequest = new SignalRModelRequest();
    if (isDelete) {
      signalObject = signalR.SingleDeleteCustomCategoryItemSignalRModelRequest;
      const arr: number[] = [];
      arr.push(this.localCustomCategoryItem!.CustomCategoryId);
      signalObject.SelectedIds = arr;
      signalObject.Description = this.$t(signalObject.SubmitMessage, {
        selectedCount: arr.length, // Just single item delete
        user: signalR.GetUserName(
          signalObject,
          this.$t('UserName.You').toString(),
        ),
      }).toString();
    } else {
      if (this.localCustomCategoryItem!.CustomCategoryId === 0) {
        signalObject =
               signalR.SingleCreateCustomCategoryItemSignalRModelRequest;
      } else {
        signalObject =
               isCustomCategoryDetails === true ?
                 signalR.SingleDetailEditCustomCategoryItemSignalRModelRequest :
                 signalR.SingleUpdateCustomCategoryItemSignalRModelRequest;
        const arr: number[] = [];
        arr.push(this.localCustomCategoryItem!.CustomCategoryId);
        signalObject.SelectedIds = arr;
      }
      signalObject.Description = t(signalObject.SubmitMessage, {
        user: signalR.GetUserName(
          signalObject,
          t('UserName.You').toString(),
        ),
      }).toString();
    }
    signalObject.ApplicationId = Number(this.appInfo.ApplicationId);
    signalObject.EventId = Number(this.appInfo.ApplicationInstanceId);
    signalObject.JSONData = JSON.stringify({
      selectedName: this.localCustomCategoryItem!.Title,
    });
    signalR.DisableCustomCategoryItemsAndAddToNotificationPopup(signalObject);
    return signalObject;
  }
  // Set the value in local variable
  private CustomCategoryMultimediaItemShowImageUploadComponent(val: any) {
    this.showMultimediaFileUploadProcess = val.showFileUploadProcess;
    this.currentEditMultimediaItem = val.selectedMultimediaitem;
  }
  private CustomCategoryMultimediaItemEditedOperation(
    multimedia: CustomCategoryMultimediaItem,
  ) {
    const objIndex = this.localCustomCategoryItem!.CustomCategoryMedias!.findIndex(
      (obj: CustomCategoryMultimediaItem) => obj.Id === multimedia.Id,
    );
    if (objIndex === -1) {
      // Created
      this.localCustomCategoryItem!.CustomCategoryMedias!.push(multimedia);
    } else {
      // Updated
      if(this.localCustomCategoryItem !== null && this.localCustomCategoryItem !== undefined && this.localCustomCategoryItem.CustomCategoryMedias !== null && this.localCustomCategoryItem.CustomCategoryMedias !== undefined) {
        this.localCustomCategoryItem.CustomCategoryMedias[objIndex] = multimedia;
      }
    }
  }
  private CustomCategoryMultimediaItemDeletedOperation(multimediaId: number) {
    const objIndex = this.localCustomCategoryItem!.CustomCategoryMedias!.findIndex(
      (obj: CustomCategoryMultimediaItem) => obj.Id === multimediaId,
    );
    if (objIndex > -1) {
      this.localCustomCategoryItem!.CustomCategoryMedias!.splice(objIndex, 1);
    }
  }
  private CustomCategory360ImageItemDeletedOperation(multimediaId: number) {
    const objIndex = this.localCustomCategoryItem!.CustomCategoryMedias360!.findIndex(
      (obj: CustomCategoryMultimediaItem) => obj.Id === multimediaId,
    );
    if (objIndex > -1) {
      this.localCustomCategoryItem!.CustomCategoryMedias360!.splice(
        objIndex,
        1,
      );
    }
  }
  private CloseValidationSummary(visible: boolean) {
    this.visibleValidationSummary = visible;
  }
  private ClickDeleteCustomCategory() {
    // show modal popup for confirmation
    this.modalPopupVisible = true;
    this.showCloseButton = true;
    this.showSectionModalPopup = this.NotificationCustomCategoryDeleted;
    this.modalPopupContent = this.$t('Information.Delete').toString();
  }
  private ShowSharedEvents(val: any) {
  // click method for shared with other event link
    if (!this.isDisabledShareWithOtherEvent()) {
      this.isShowCustomCategoryEditDetailView = false;
      this.showShareWithOtherEventsProcess = val;
    }
  }
  private showAttachWithSharedEvents(val: any) {
    this.showShareWithOtherEventsProcess = val.showShareWithOtherEevents;
    this.localAllEvents = val.events;
    this.SetSelectedLinkedEventsToLocalItem();
    setTimeout(() => {
      Root.emit(
        'selected-participant-group-is',
        this.localCustomCategoryItem!.ParticipantGroupIds,
      );
    }, 200);
  }
  private SetSelectedLinkedEventsToLocalItem() {
    this.localCustomCategoryItem!.LinkedEvents = [];
    const objIndex = this.localAllEvents.findIndex(
      (obj: EventItem) => obj.IsLinked === true,
    );
    if (objIndex > -1) {
      const eventItems: number[] = [];
      this.localAllEvents.forEach(function(value) {
        if (value.IsLinked === true) {
          eventItems.push(value.Id);
        }
      });
      this.localCustomCategoryItem!.LinkedEvents = eventItems;
    }
  }
  private SendNotificationRequestForSharedEvents(obj: any) {
    if (obj.showLoading) {
      setTimeout(() => {
        Root.emit('show-notification-popup', true);
        Root.emit('show-notification-loading', true);
      }, 100);
    } else {
      Root.emit('customCategory-uncheck-select-all', true);
      const signalR: CustomCategorySignalRModel = new CustomCategorySignalRModel(
        Root,
        Store,
        this.$t,
      );
      let signalObject: SignalRModelRequest = new SignalRModelRequest();
      signalObject = signalR.CustomCategorySharedEventsSignalRModelRequest;
      const arr: number[] = [];
      arr.push(this.localCustomCategoryItem!.CustomCategoryId);
      signalObject.SelectedIds = arr;
      signalObject.Description = t(signalObject.SubmitMessage, {
        user: signalR.GetUserName(
          signalObject,
          t('UserName.You').toString(),
        ),
      }).toString();

      // customCategoryItem.Title = this.selectedCustomCategoryItem!.Title;
      signalObject.JSONData = signalR.GetCustomCategoryItemToSendToSignalR(
        this.selectedCustomCategoryItem!,
      );
      signalR.PerformCustomCategorySingleOperation(signalObject, true);
      this.localAllEvents = Object.assign(
        [],
        obj.evnts,
      );
      this.showShareWithOtherEventsProcess = obj.showLoading;
      this.SetSelectedLinkedEventsToLocalItem();
      if (this.localCustomCategoryItem!.CustomCategoryId > 0) {
        const objIndex = this.localAllEvents.findIndex(
          (event: EventItem) => (event.Id === signalObject.EventId && event.IsLinked === true),
        );
        if (objIndex === -1) {
          this.Cancel(this.v$);
          Root.emit('customCategory-item-single-delete-operation', []);
        }
      }
      setTimeout(() => {
        Root.emit(
          'selected-participant-group-is',
          this.localCustomCategoryItem!.ParticipantGroupIds,
        );
      }, 200);
    }

  }

  // delete custom category
  private DeleteCustomcategory() {
    const signalObject = this.SendNotificationRequest(true);

    const arr: number[] = [];
    arr.push(this.localCustomCategoryItem!.CustomCategoryId);

    FetchData('/CustomCategory/DeleteCustomCategories', JSON.stringify({
      customCategoriesIds: arr,
    }))
      .then((response) => {
        if (response.Success) {
          // Successful delete
          this.SendNotificationRequestToQueue(
            signalObject,
            this.localCustomCategoryItem!,
          );
          this.$emit(
            'customCategory-item-delete-operation',
            this.localCustomCategoryItem,
          );
        }
        this.showLoading = false;
      })
      .catch(() => {
        this.showLoading = false;
      });
  }
  private SendNotificationRequestToQueue(
    signalObject: SignalRModelRequest,
    customCategoryItem: CustomCategoryItem,
  ) {
    const signalR: CustomCategorySignalRModel = new CustomCategorySignalRModel(
      Root,
      Store,
      this.$t,
    );
      // Case of Single Create as we get the id once response come from DB
    if (signalObject.SelectedIds.length === 0) {
      const arr: number[] = [];
      arr.push(customCategoryItem.CustomCategoryId);
      signalObject.SelectedIds = arr;
    }
    signalObject.JSONData = signalR.GetCustomCategoryItemToSendToSignalR(
      customCategoryItem,
    );
    signalR.PerformCustomCategorySingleOperation(signalObject);
  }

  // Search API for searching participants based on string
  private SearchApiCall(searchString: string, searchLinkedListing: SearchItem[], searchPlaceholder: string, searchTitle: string, fromSearchComponent: boolean) {
    FetchData(('/CustomCategory/GetParticipantsForLinkingWithCustomCategory?count='+this.participantGetListCount  + '&searchString=' + searchString), '')
      .then((response) => {
        if(fromSearchComponent === false) {
          this.searchLinkedListing=searchLinkedListing;
          this.searchPlaceholder=searchPlaceholder;
          this.searchTitle=searchTitle;
          if(this.localCustomCategoryItem !== null && this.localCustomCategoryItem !== undefined) {
            this.searchNoticeLineText=t('SearchNoticeLineText', { type: this.localCustomCategoryItem.ContentTypeV2 }).toString();
          }
          this.selectedCustomerTypeId = this.localCustomCategoryItem!.CustomCategoryId;
          if(this.localCustomCategoryItem!.ContentTypeV2.toLowerCase()===CustomCategoryTypeEnum[CustomCategoryTypeEnum.Sponsor].toString().toLowerCase()) {
            this.isMultipleParticipant=true;
          }
          if(this.localCustomCategoryItem!.ContentTypeV2.toLowerCase()===CustomCategoryTypeEnum[CustomCategoryTypeEnum.Speaker].toString().toLowerCase()) {
            this.isMultipleParticipant=false;
          }
          this.searchListing= response as SearchItem[];
          this.searchListing.forEach((x1) => x1.IsAdded=false);
          if(response.length === 0) {
            this.noParticipantFoundText = this.$t('CustomCategory.NoParticipantInEvent').toString();
            this.hasParticipant = false;
          } else {
            this.hasParticipant = true;
          }
          Root.emit('change-linkedlisting-after-search',this.searchLinkedListing);
        } else {
          this.hasParticipant = true;
          this.searchListing=response as SearchItem[];
          this.searchListing.forEach((x1) => x1.IsAdded=false);
          Root.emit('change-listing-after-search',this.searchListing);
          if(response.length === 0) {
            this.noParticipantFoundText = this.$t('CustomCategory.NoParticipantFound').toString();
          }
        }
        this.showSearchModule=true;
      })
      .catch(() => {
        alert('Error');
      });
  }

  @Watch('selectedCustomCategoryItem')
  private onPropertyChanged(
    value: CustomCategoryItem,
    oldvalue: CustomCategoryItem,
  ) {
    this.localCustomCategoryItem = value;
    this.state.localCustomCategoryItem = this.localCustomCategoryItem;
    if(this.selectedItem!.VodDetail === undefined){
      this.isSharedVideoMessageShow =  false;
    } else {
      if(this.selectedItem!.VodDetail.Id > 0){
        this.isSharedVideoMessageShow =  true;
      } else {
        this.isSharedVideoMessageShow =  false;
      }
    }
    this.SetVodDetail();
    this.showMultimediaFileUploadProcess = false;
    this.showFileUploadProcess = false;
    this.showExternalContentFileUploadProcess = false;
    this.showAddProgramProcess = false;
    this.setSelectedType(value);
    this.isDisabled = false;
    if (value.CustomCategoryId > 0) {
      this.isDisabled = true;
    }
    const newItem = CustomCategoryFolder.createEmpty();
    this.defaultFolder = newItem;
    this.visibleValidationSummary = false;
    this.validationErrorFields = [];
    /* Our values are cleared when we are on create new information item and there is a notification for the new
    single created item. so we put the old value */
    if(this.localCustomCategoryItem.CustomCategoryId === 0) {
      if (value.CategoryFolder == null) {
        value.CategoryFolder = (oldvalue.CategoryFolder === undefined || oldvalue.CategoryFolder === null) ? newItem : oldvalue.CategoryFolder ;
      }
      this.localCustomCategoryItem.Title = oldvalue.Title === '' ? this.localCustomCategoryItem.Title : oldvalue.Title;
      this.localCustomCategoryItem.Address = oldvalue.Address === '' ? this.localCustomCategoryItem.Address : oldvalue.Address;
      this.localCustomCategoryItem.City = oldvalue.City === '' ? this.localCustomCategoryItem.City : oldvalue.City;
      this.localCustomCategoryItem.Latitude = oldvalue.Latitude === 0 ? this.localCustomCategoryItem.Latitude : oldvalue.Latitude;
      this.localCustomCategoryItem.Longitude = oldvalue.Longitude === 0 ? this.localCustomCategoryItem.Longitude : oldvalue.Longitude;
      this.localCustomCategoryItem.IconUrl = oldvalue.IconUrl === '' ? this.localCustomCategoryItem.IconUrl : oldvalue.IconUrl;
      this.localCustomCategoryItem.ThumbUrl = oldvalue.ThumbUrl === '' ? this.localCustomCategoryItem.ThumbUrl : oldvalue.ThumbUrl;
      this.localCustomCategoryItem.ContentUrl = oldvalue.ContentUrl === '' ? this.localCustomCategoryItem.ContentUrl : oldvalue.ContentUrl;
    } else {
      if (value.CategoryFolder == null) {
        value.CategoryFolder = newItem;
      }
    }
  }
  @Watch('selectedBaseCustomCategoryRenderer')
  private onTypeChanged(
    value: BaseCustomCategoryRenderer,
  ) {
    this.localSelectedBaseCustomCategoryRenderer = value;
    this.localCustomCategoryItem!.ContentTypeV2 = value.CustomCategoryType;
    this.IsContentUrlRequired();
    this.showFullImageOption = this.CheckIfInformationTypeIsSponsor();
    if(this.localCustomCategoryItem !== null && this.localCustomCategoryItem !== undefined && this.localCustomCategoryItem.CustomCategoryId === 0 && this.showFullImageOption) {
      this.localCustomCategoryItem.IsFullImage = true;
    }
  }

  // #region all methods linked with ListOfParticipantGroups component
  private SetListParticipantGroups(listOfGroups: ParticipantGroups[], hasSelectedGroups: boolean) {
    // set groups in local item
    this.hasSelectedGroups = hasSelectedGroups;
    this.localCustomCategoryItem!.ParticipantGroups = listOfGroups;
    this.selectedParticipantGroups = listOfGroups;
    this.localCustomCategoryItem!.ParticipantGroupIds = [];
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < listOfGroups.length; i++) {
      this.localCustomCategoryItem!.ParticipantGroupIds.push(listOfGroups[i].Id);
    }
    if(this.localCustomCategoryItem !== null && this.localCustomCategoryItem !== undefined && this.localCustomCategoryItem.CustomCategoryId === 0) {
      this.localCustomCategoryItem.HideFromEventWebpage = this.selectedParticipantGroups.length > 0 ? true : false;
    }
  }
  private setListInLocalItem(listOfGroups: ParticipantGroups[]) {
    // set groups in local item
    this.localCustomCategoryItem!.ParticipantGroups = listOfGroups;
    this.selectedParticipantGroups = listOfGroups;
  }

  private isDisabledShareWithOtherEvent(){
    if (this.selectedParticipantGroups.length > 0) {
      return true ;
    }
    return false;
  }

  private getInformationString(val: boolean) {
    // for toolip information
    if (this.isDisabledShareWithOtherEvent()) {
      this.showInformation = val;
    }
  }
}
export default toNative(CustomCategoryChildComponent);
