import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-68e780d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "customCategoryListViewPane",
  class: "scroll-top gridlisting"
}
const _hoisted_2 = { class: "flex-container" }
const _hoisted_3 = {
  key: 0,
  class: "right-container-margin"
}
const _hoisted_4 = { class: "right-container-nav" }
const _hoisted_5 = { class: "width25" }
const _hoisted_6 = { class: "width50" }
const _hoisted_7 = { class: "main-heading primary-heading" }
const _hoisted_8 = { class: "add-btn-position" }
const _hoisted_9 = {
  key: 0,
  class: "empty-msg-position"
}
const _hoisted_10 = { class: "text-msg-padding" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { class: "margin-top-40" }
const _hoisted_14 = ["id", "indexFolder"]
const _hoisted_15 = ["indexFolder"]
const _hoisted_16 = {
  key: 1,
  class: "right-container-margin margin-top-15"
}
const _hoisted_17 = {
  key: 2,
  class: "right-container-margin margin-top-15"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomCategoryFolderListViewEditItemPane = _resolveComponent("CustomCategoryFolderListViewEditItemPane")!
  const _component_CustomCategoryFolderEditItemPane = _resolveComponent("CustomCategoryFolderEditItemPane")!
  const _component_ImageProcess = _resolveComponent("ImageProcess")!
  const _component_InformationFontIcon = _resolveComponent("InformationFontIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.showImageProcess && !_ctx.showFontAwesomeIconImageProcess)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("button", {
                  id: "btnCloseCustomCategoryFolder",
                  class: "btn btn-secondary btn-size-default",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.CloseFolder()))
                }, _toDisplayString(_ctx.$t("Button.Close")), 1)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("CustomCategoryFolder.ManageFolders")), 1)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("button", {
                  id: "btnAddCustomCategoryFolder",
                  class: "btn btn-primary btn-size-default",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.AddFolder()))
                }, _toDisplayString(_ctx.$t("Button.Add")), 1)
              ])
            ]),
            ((_ctx.localFolderItems==null || _ctx.localFolderItems.length==0) && _ctx.localFolderItem==null)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("p", _hoisted_10, [
                    _createElementVNode("i", {
                      class: "font-size-12",
                      innerHTML: _ctx.$t('CustomCategoryFolder.EmptyScreenMessageLine1')
                    }, null, 8, _hoisted_11)
                  ]),
                  _createElementVNode("p", null, [
                    _createElementVNode("span", {
                      class: "font-size-12",
                      innerHTML: _ctx.$t('CustomCategoryFolder.EmptyScreenMessageLine2')
                    }, null, 8, _hoisted_12)
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_13, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localFolderItems, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  id: 'divCustomCategoryFolderItem'+index,
                  key: item.Id,
                  indexFolder: index,
                  class: "margin-top-15"
                }, [
                  _createVNode(_component_CustomCategoryFolderListViewEditItemPane, {
                    "selected-edit-folder-item": item,
                    "applicationborder-color": _ctx.loaderBorderColor,
                    onCustomCategoryFolderItemEditOperation: _cache[2] || (_cache[2] = ($event: any) => (_ctx.SetCurrentlocalItem($event))),
                    onCustomCategoryFolderItemImageUploadOperation: _cache[3] || (_cache[3] = ($event: any) => (_ctx.clickOnImage($event))),
                    onFolderInformationFonticon: _cache[4] || (_cache[4] = ($event: any) => (_ctx.clickOnFontImage($event))),
                    onCustomCategoryFolderItemImageUploadCompleted: _cache[5] || (_cache[5] = ($event: any) => (_ctx.CustomCategoryFolderItemImageUploadCompleted($event)))
                  }, null, 8, ["selected-edit-folder-item", "applicationborder-color"])
                ], 8, _hoisted_14))
              }), 128))
            ]),
            _createElementVNode("div", {
              id: "customCategoryFolderItemEditPane",
              indexFolder: _ctx.localFolderItems.length
            }, [
              (_ctx.localFolderItem)
                ? (_openBlock(), _createBlock(_component_CustomCategoryFolderEditItemPane, {
                    key: 0,
                    "selected-folder-item": _ctx.localFolderItem,
                    "applicationborder-color": _ctx.loaderBorderColor,
                    onCustomCategoryFolderItemEditOperation: _cache[6] || (_cache[6] = ($event: any) => (_ctx.SetCurrentlocalItem($event))),
                    onCustomCategoryFolderItemCancelOperation: _cache[7] || (_cache[7] = ($event: any) => (_ctx.CustomCategoryFolderItemCancelOperation())),
                    onCustomCategoryFolderItemImageUploadOperation: _cache[8] || (_cache[8] = ($event: any) => (_ctx.clickOnImage($event))),
                    onFolderInformationFonticon: _cache[9] || (_cache[9] = ($event: any) => (_ctx.clickOnFontImage($event)))
                  }, null, 8, ["selected-folder-item", "applicationborder-color"]))
                : _createCommentVNode("", true)
            ], 8, _hoisted_15)
          ]))
        : _createCommentVNode("", true),
      (_ctx.showImageProcess)
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
            _createVNode(_component_ImageProcess, {
              "image-src": _ctx.GetImageUrl(),
              "image-full-src": _ctx.GetImageUploadUrl(),
              "heading-title": _ctx.$t('ImageProcess.iconImage'),
              onSetImageIconUrl: _ctx.SetImageIconUrl,
              onSelectBack: _ctx.clickOnImage,
              onGetPreviousIconUrl: _ctx.GetPreviousIconUrl
            }, null, 8, ["image-src", "image-full-src", "heading-title", "onSetImageIconUrl", "onSelectBack", "onGetPreviousIconUrl"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.showFontAwesomeIconImageProcess)
        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
            _createVNode(_component_InformationFontIcon, {
              "heading-title": _ctx.$t('ImageProcess.iconImage'),
              "font-awesome-icons": _ctx.fontAwesomeIcons,
              "custom-category-folder": _ctx.GetCustomCategoryFolder(),
              onSelectBack: _ctx.clickOnFontImage,
              onUpdateFontawesomeIcon: _ctx.UpdateFontAwesomeIcon
            }, null, 8, ["heading-title", "font-awesome-icons", "custom-category-folder", "onSelectBack", "onUpdateFontawesomeIcon"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}