
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
import loading from '@/components/Common/loading.vue';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import { VueDraggableNext } from 'vue-draggable-next';
import { CustomCategoryItem } from '../../../entities/CustomCategory/CustomCategoryItem';
import { CustomCategoryFolder } from '../../../entities/CustomCategory/CustomCategoryFolder';
import {SignalRModelRequest} from '@/signalRRequest/SignalRModelRequest';
import { CustomCategorySignalRModel } from '@/signalRRequest/CustomCategory/CustomCategorySignalRModel';
import ModuleEnum from '@/enums/ModuleEnum';
import SubModuleEnum from '@/enums/SubModuleEnum';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import CustomCategoryTypeEnum from '@/enums/CustomCategoryTypeEnum';
import { VodEntityItemModel } from '@/entities/VodEntityItemModel';
import VodTypeEnums from '@/enums/VodTypeEnums';
import VodModuleTypeEnum from '@/enums/VodModuleTypeEnum';
import {  VodDetail } from '@/entities/VodDetail';
import { Root, Store, FetchData } from '@/main';
import VodMuxVideoData from '@/entities/Emits/Common/VodMuxVideoData';
import UpdateFolderListing from '@/entities/Emits/Information/UpdateFolderListing';
@Component({
  components: {
    loading,
    ModalPopup,
    draggable: VueDraggableNext,
  },
})
class CustomCategoryListView extends Vue {
  // constants
  private readonly All: string = 'All';
  private readonly EditBoth = 'both';
  private readonly EditIcon = 'icon';
  private readonly EditFeatured = 'featured';
  private readonly defaultDivBackgroundColor: string = '#f9f9f9';
  private readonly whiteColor: string = '#FFFFFF';
  private readonly missingImage: string = require('@/assets/Images/ImageMissing.png');
  private readonly WarningMessage = 'WarningMessage';
  private readonly Notification = 'Notification';
  private readonly NotificationSorting = 'NotificationSorting';
  private readonly NotificationSharedEventsUpdated =
    'NotificationSharedEventsUpdated';
  private readonly Multimedia = 'Multimedia';
  private readonly MultimediaDelete = 'Multimedia-Delete';
  private readonly Image360 = 'Image360';
  private readonly Image360Delete = 'Image360-Delete';
  private readonly LinkedParticipant = 'LinkedParticipant';
  private readonly NotificationMultimediaUpdated =
    'NotificationMultimediaUpdated';
  private readonly NotificationMultimediaDeleteUpdated =
    'NotificationMultimediaDeleteUpdated';
  private readonly Notification360ImagesUpdated =
    'Notification360ImagesUpdated';
  private readonly Notification360ImagesDeleteUpdated =
    'Notification360ImagesDeleteUpdated';
  private readonly NotificationUpdateProgramListForInformationLinking =
    'NotificationUpdateProgramListForInformationLinking';
  private readonly NotificationUpdateProgramListForInformationLinkingBulk =
    'NotificationUpdateProgramListForInformationLinkingBulk';
  private readonly NotificationUpdateFolder = 'NotificationUpdateFolder';
  private readonly NotificationUpdateFolderWithSelectedItem =
    'NotificationUpdateFolderWithSelectedItem';
  // props
  @Prop() private selectAllItems?: boolean;
  @Prop() private applicationborderColor?: string;
  @Prop() private customCategoryItems?: CustomCategoryItem[];
  @Prop() private folders?: string[];
  @Prop() private editingCustomCategoryItem?: CustomCategoryItem;
  @Prop() private folderItems?: CustomCategoryFolder[];
  @Prop() private createNewUnsavedFlag?: boolean;
  // #region Modal popup
  private modalPopupVisible = false;
  private showCloseButton = true;
  private modalPopupShowCancelButton = true;
  private modalPopupCancelButtonText = '';
  private modalPopupConfirmButtonText = '';
  private modalPopupContent: string = this.getMoveToAnotherItemMessage();
  private showSectionModalPopup = '';
  // #endregion end modal popup
  private dragging = false;
  private emptySelectedItems = true;
  private customCategoryFolders?: string[] = [];
  private filteredCustomCategoryItems: CustomCategoryItem[] = [];
  private temporarySelectedItems: CustomCategoryItem[] = [];
  private isMobileView = false;
  private selectedItems: CustomCategoryItem[] = [];
  private selectedCustomCategoryItem: CustomCategoryItem =
    CustomCategoryItem.createEmpty();
  private selectedCheckboxCount = 0;
  private showLoading = false;
  private selectedFolderName: string = this.All;
  private selectedItemId = 0;
  private selectedCustomCategoryItemValue: CustomCategoryItem[] = [];
  private notificationSignalRSorting?: SignalRModelRequest;
  private loaderBorderColor = '';
  private fromCreateNew = false;
  private customCategoryEditPaneValues: any = null;
  // #region multimedia
  private customCategoryDetailInfo: any = [];
  private localCreateNewUnsavedFlag = false;
  private selectedCustomCategoryVODItem: VodEntityItemModel = VodEntityItemModel.createEmpty();
  private AssetReady = false;
  private readonly NotificationVideoUploadedSuccessfully = 'NotificationVideoUploadedSuccessfully';
  private readonly NotificationVideoDeletedSuccessfully = 'NotificationVideoDeletedSuccessfully';
  // #endregion
  private created() {
    this.GetAllInformations();
  }
  private mounted() {
    // load folder dropdown
    this.customCategoryFolders = this.folders;
    this.BindFoldersDropdown(this.folders);
    this.localCreateNewUnsavedFlag = this.createNewUnsavedFlag!;
    // Check for mobile view
    if (window.innerWidth <= 480) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    // To uncheck all the checkboxes
    Root.on('clearCustomCategoryCheckboxes', () => {
      this.ClearAllCheckBoxes();
      this.resetCustomCategoryItemsBackgroundColor();
    });
    // To get data for new edit pane
    Root.on(
      'createEmptyCustomCategoryItem',
      (newItem: CustomCategoryItem) => {
        this.ClearAllCheckBoxes();
        this.selectedItems = [newItem ];
        this.ResetItemsBackgroundColor();
      },
    );
    // To scroll to the newely created item
    Root.on(
      'scroll-to-newly-added-customCategory-item',
      (index: number) => {
        this.RedirectToCustomCategoryItem(index );
      },
    );
    Root.on('reload-customCategory-refresh', () => {
      this.FillCustomCategoryDetails(this.selectedItems[0]);
    });
    // updating the data for information item
    Root.on(
      'selected-customCategory-item-notification-completed',
      (notification: SignalRModelRequest) => {
        const signalR: CustomCategorySignalRModel =
          new CustomCategorySignalRModel(
            Root,
            Store,
            this.$t,
          );
        signalR.CustomCategorySignalRModelResponseReceived(notification);
        if (notification.IsSuccess) {
          this.PerformOperationOnList(notification);
        }
      },
    );
    // To disable the listing item(s) when any update operation performed
    Root.on(
      'disable-CustomCategory-items',
      (customCategoryIds: number[]) => {
        (customCategoryIds ).forEach((element) => {
          this.filteredCustomCategoryItems.find(
            (x) => x.CustomCategoryId === element,
          )!.DisableItem = true;
          this.customCategoryItems!.find(
            (x) => x.CustomCategoryId === element,
          )!.DisableItem = true;
        });
      },
    );
    // To enable the listing item(s) when any update operation performed
    Root.on(
      'enable-CustomCategory-items',
      (customCategoryIds: number[]) => {
        (customCategoryIds ).forEach((element) => {
          this.filteredCustomCategoryItems.find(
            (x) => x.CustomCategoryId === element,
          )!.DisableItem = false;
          this.customCategoryItems!.find(
            (x) => x.CustomCategoryId === element,
          )!.DisableItem = false;
        });
      },
    );
    Root.on('reload-customCategory-detail', () => {
      const selectedCustomCategoryItem: CustomCategoryItem =
        this.selectedItems[0];
      this.selectedItems = [];
      this.selectedItems.push(selectedCustomCategoryItem);
      this.selectedCheckboxCount = 1;
      this.FillCustomCategoryDetails(selectedCustomCategoryItem);
    });
    // When delete bulk operation is performed
    Root.on(
      'customCategory-item-bulk-delete-operation',
      (notification: SignalRModelRequest) => {
        (notification ).SelectedIds.forEach((element) => {
          this.customCategoryItemDeletedOperation(element);
        });
      },
    );
    // when a single information item is deleted
    Root.on(
      'customCategory-item-single-delete-operation',
      (customCategoryId: number) => {
        this.customCategoryItemDeletedOperation(customCategoryId );
      },
    );
    // When addition / updation of image bulk operation is performed
    Root.on(
      'customCategory-item-bulk-imageUrl-operation',
      (notification: SignalRModelRequest) => {
        this.CustomCategoryItemIconChangeOperation(notification );
      },
    );
    // To update the program listing
    Root.on('update-program-in-listing', () => {
      this.UpdateLinkedPrograms();
    });
    // To update the folder in listing
    Root.on('update-folder-in-listing', () => {
      this.UpdateFolderInListing();
    });
    // When create new button is clicked
    Root.on('from-createnew', (val: boolean) => {
      this.fromCreateNew = val ;
    });
    // To update program linking
    Root.on(
      'update-programs-linking-in-information',
      (notification: SignalRModelRequest) => {
        if (
          notification.Module === ModuleEnum.Program &&
          (notification.SubModule === SubModuleEnum.SingleDelete ||
            SubModuleEnum.SingleCreate ||
            SubModuleEnum.Delete ||
            SubModuleEnum.EditIconsAndImages ||
            SubModuleEnum.LinkInformation)
        ) {
          this.UpdateProgramListForInformationLinking(notification);
        }
      },
    );
    // To update folder dropdown listing
    Root.on(
      'update-folder-listing-dropdown',
      (updateListing: boolean) => {
        if (updateListing) {
          this.UpdateFolderList(this.customCategoryItems!);
        }
      },
    );
    Root.on(
      'selected-CustomCategory-video-upload-progress',
      (notification: SignalRModelRequest) => {
        this.PerformOperationOnList(notification );
      },
    );
    Root.on(
      'update-folder-listing-dropdown-with-notification',
      (updatedFolderListing: UpdateFolderListing) => {
        const signalR: CustomCategorySignalRModel =
          new CustomCategorySignalRModel(
            Root,
            Store,
            this.$t,
          );
        // Checking whether the operation is performed by same user
        if (signalR.IsLoggedInUserIsMe(updatedFolderListing.notification)) {
          this.UpdateFolderList(this.customCategoryItems!);
          // Checking if listing is filtered according to some folder other than All
          if (
            this.selectedFolderName !== this.All &&
            this.selectedFolderName !== ''
          ) {
            if (this.selectedFolderName) {
              const objIndex = this.customCategoryFolders!.findIndex(
                (obj: string) => obj === this.selectedFolderName,
              );
              // Check if the folder name is updated
              if (objIndex === -1) {
                this.UpdateSelectedFolder(updatedFolderListing.pt.Name);
              }
            }
          }
        } else {
          // If the operation is performed by different user i.e., other than logged in user
          this.UpdateFolderList(this.customCategoryItems!);
          // Checking if listing is filtered according to some folder other than All
          if (
            this.selectedFolderName !== this.All &&
            this.selectedFolderName !== ''
          ) {
            this.UpdateSelectedFolder(updatedFolderListing.pt.Name);
            this.modalPopupVisible = true;
            this.showCloseButton = false;
            this.showSectionModalPopup = this.NotificationUpdateFolder;
            this.modalPopupShowCancelButton = false;
            this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
            this.modalPopupCancelButtonText =
              this.$t('CancelButton').toString();
            this.modalPopupContent = this.$t('Message.FolderUpdatedBy', {
              username: updatedFolderListing.notification.UserName,
            }).toString();
          } else if (this.selectedCustomCategoryItemValue.length === 1) {
            // If any item is selected in listing screen
            if(this.selectedCustomCategoryItemValue[0] !== null && this.selectedCustomCategoryItemValue[0] !== undefined) {
              if (
                this.selectedCustomCategoryItemValue[0].CategoryFolder !== null && this.selectedCustomCategoryItemValue[0].CategoryFolder !== undefined && this.selectedCustomCategoryItemValue[0].CategoryFolder.Id !== null && this.selectedCustomCategoryItemValue[0].CategoryFolder.Id !== undefined && this.selectedCustomCategoryItemValue[0].CategoryFolder.Id === updatedFolderListing.pt.Id
              ) {
                // Show popup if selected item contains the same folder which got updated
                this.modalPopupVisible = true;
                this.showCloseButton = false;
                this.showSectionModalPopup =
                this.NotificationUpdateFolderWithSelectedItem;
                this.modalPopupShowCancelButton = false;
                this.modalPopupConfirmButtonText =
                this.$t('Button.Ok').toString();
                this.modalPopupCancelButtonText =
                this.$t('CancelButton').toString();
                this.modalPopupContent = this.$t('Message.ItemUpdatedBy', {
                  username: updatedFolderListing.notification.UserName,
                }).toString();
              }
            }
          }
        }
      },
    );
  }
  // Update the program linked with the information item
  private UpdateProgramListForInformationLinking(
    notification: SignalRModelRequest,
  ) {
    // Check if only one item is selected in listing screen
    if (this.selectedItems.length === 1) {
      if (this.selectedItems[0].CustomCategoryId > 0) {
        this.modalPopupVisible = true;
        this.showCloseButton = false;
        this.showSectionModalPopup =
          this.NotificationUpdateProgramListForInformationLinking;
        this.modalPopupShowCancelButton = false;
        this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
        this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
        this.modalPopupContent = this.$t('Message.ItemUpdatedBy', {
          username: notification.UserName,
        }).toString();
      }
    } else if (this.selectedItems.length > 1) {
      // If more than one item is selected
      this.modalPopupVisible = true;
      this.showCloseButton = false;
      this.showSectionModalPopup =
        this.NotificationUpdateProgramListForInformationLinkingBulk;
      this.modalPopupShowCancelButton = false;
      this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
      this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
      this.modalPopupContent = this.$t('Message.ItemsUpdatedBy', {
        username: notification.UserName,
      }).toString();
    } else {
      // If no item is selected
      Root.emit('reload-customCategories-data');
    }
  }
  private UpdateFolderInListing() {
    Root.emit('reload-customCategories-data');
  }

  private UpdateLinkedPrograms() {
    Root.emit('reload-customCategories-data');
  }
  // Change icon bulk operation
  private CustomCategoryItemIconChangeOperation(
    notification: SignalRModelRequest,
  ) {
    const customCategoryObject: any = JSON.parse(notification.JSONData);
    const self = this;
    notification.SelectedIds.forEach((item) => {
      const objIndex = self.customCategoryItems!.findIndex(
        (obj: CustomCategoryItem) => obj.CustomCategoryId === item,
      );
      if (objIndex > -1) {
        const pItem: CustomCategoryItem = self.customCategoryItems![objIndex];
        if (customCategoryObject.selectedBatch === self.EditBoth) {
          pItem.IconUrl = customCategoryObject.croppedimageIconUrl;
          pItem.MainMediaFullUrl = customCategoryObject.imageIconUrl;
          pItem.FeaturedImageUrl =
            customCategoryObject.croppedFeaturedImageIconUrl;
          pItem.FeaturedOrginalImageUrl = customCategoryObject.featuredImageUrl;
          pItem.TopPosition = customCategoryObject.topPosition;
          pItem.LeftPosition = customCategoryObject.leftPosition;
          pItem.ShowFeatureImageBelowTitle =
            customCategoryObject.showFeaturedImageBelowTitle;
          pItem.FeaturedImageWidth = customCategoryObject.featuredImageWidth;
          pItem.FeaturedImageHeight = customCategoryObject.FeaturedImageHeight;
        } else if (customCategoryObject.selectedBatch === self.EditIcon) {
          pItem.IconUrl = customCategoryObject.croppedimageIconUrl;
          pItem.MainMediaFullUrl = customCategoryObject.imageIconUrl;
        } else if (customCategoryObject.selectedBatch === self.EditFeatured) {
          pItem.FeaturedImageUrl =
            customCategoryObject.croppedFeaturedImageIconUrl;
          pItem.FeaturedOrginalImageUrl = customCategoryObject.featuredImageUrl;
          pItem.TopPosition = customCategoryObject.topPosition;
          pItem.LeftPosition = customCategoryObject.leftPosition;
          pItem.ShowFeatureImageBelowTitle =
            customCategoryObject.showFeaturedImageBelowTitle;
          pItem.FeaturedImageWidth = customCategoryObject.featuredImageWidth;
          pItem.FeaturedImageHeight = customCategoryObject.FeaturedImageHeight;
        }
        if(self.customCategoryItems !== null && self.customCategoryItems !== undefined) {
          self.customCategoryItems[objIndex] = pItem;
        }
      }
    });
  }
  // Redirect to specific program item by passing index of program item
  private RedirectToCustomCategoryItem(index: number) {
    setTimeout(() => {
      const element = document.querySelectorAll('[index="' + index + '"]')[0];
      if (element) {
        element.scrollIntoView({
          block: 'nearest',
          behavior: 'smooth',
          inline: 'nearest',
        });
      }
    }, 1500);
  }
  // Response from signalR to add/update/delete item
  private PerformOperationOnList(notification: SignalRModelRequest) {
    if (
      notification.Module === ModuleEnum.Information &&
      notification.SubModule === SubModuleEnum.SingleDelete
    ) {
      // If single information is deleted
      if (notification.SelectedIds.length > 0) {
        if (
          this.selectedItems.length === 1 &&
          this.selectedItems[0].CustomCategoryId === notification.SelectedIds[0]
        ) {
          this.CheckIfDeletedItemIsOpenInEditMode(notification);
        } else {
          this.customCategoryItemDeletedOperation(notification.SelectedIds[0]);
        }
      }
    } else if (
      notification.Module === ModuleEnum.Information &&
      notification.SubModule === SubModuleEnum.Sorting
    ) {
      // If sorting opertion is performed on listing
      if (notification.SelectedIds.length > 0) {
        if (this.selectedItems.length > 0) {
          this.CheckIfSortingItemIsOpenInEditMode(notification);
        } else {
          const signalR: CustomCategorySignalRModel =
            new CustomCategorySignalRModel(
              Root,
              Store,
              this.$t,
            );
          if (!signalR.IsLoggedInUserIsMe(notification)) {
            Root.emit('reload-customCategories-data');
          }
        }
      }
    } else if (notification.Module === ModuleEnum.Information &&
      notification.SubModule === SubModuleEnum.MuxVodUpdate) {
      // If vod is updated of list item
      if (notification.VodDetail !== null) {
        this.UpdateVodDetailForCustomCategoryDetails(notification);
      }
    } else if (
      notification.Module === ModuleEnum.Information &&
      notification.SubModule === SubModuleEnum.MuxVodDelete
    ) {
      // If vod is deleted
      if (notification.SelectedIds.length > 0) {
        if (
          this.selectedItems.length === 1 &&
        this.selectedItems[0].CustomCategoryId === notification.SelectedIds[0]
        ) {
          this.CheckIfDeleteVideoIsOpenInEditMode(notification);
        }
      }
    }else if (
      notification.Module === ModuleEnum.Information &&
      notification.SubModule === SubModuleEnum.SingleDetailEdit
    ) {
      // If edit operation is performed
      const customCategoryDetails = JSON.parse(notification.JSONData);
      if (customCategoryDetails.customCategoryDetailType === this.Multimedia) {
        this.UpdateMultimediaForCustomCategoryDetails(
          notification,
          customCategoryDetails,
        );
      } else if (
        customCategoryDetails.customCategoryDetailType === this.MultimediaDelete
      ) {
        // If multimedia item is deleted
        this.DeleteMultimediaForCustomCategoryDetails(
          notification,
          customCategoryDetails,
        );
      } else if (
        customCategoryDetails.customCategoryDetailType === this.Image360
      ) {
        this.UpdateImage360ForCustomCategoryDetails(
          notification,
          customCategoryDetails,
        );
      } else if (
        customCategoryDetails.customCategoryDetailType === this.Image360Delete
      ) {
        this.DeleteImage360ForCustomCategoryDetails(
          notification,
          customCategoryDetails,
        );
      } else if (
        customCategoryDetails.customCategoryDetailType ===
        this.LinkedParticipant
      ) {
        this.UpdateLinkedParticipantsForCustomCategoryDetails(
          notification,
          customCategoryDetails,
        );
      } else {
        if (notification.SelectedIds.length > 0) {
          if (
            this.selectedItems.length === 1 &&
            this.selectedItems[0].CustomCategoryId ===
              notification.SelectedIds[0]
          ) {
            this.CheckIfUpdatedItemIsOpenInEditMode(notification);
          } else {
            // No need to reload - Jira # 2033
            // Root.emit('reload-customCategories-data');
            setTimeout(() => {
              if (this.selectedCheckboxCount === 1) {
                this.updateSelectedItem(this.selectedItems[0]);
              }
            }, 1000);
          }
        }
      }
    } else {
      Root.emit(
        'customCategory-item-edit-operation-with-notification',
        notification,
      );
    }
  }
  private CheckIfDeleteVideoIsOpenInEditMode(notification: SignalRModelRequest) {
    const signalR: CustomCategorySignalRModel = new CustomCategorySignalRModel(Root, Store, this.$t);
    if (signalR.IsLoggedInUserIsMe(notification)) {
      // Do nothing
    } else {
      this.modalPopupVisible = true;
      this.showCloseButton = false;
      this.showSectionModalPopup = this.NotificationVideoDeletedSuccessfully;
      this.modalPopupShowCancelButton = false;
      this.modalPopupConfirmButtonText = this.$t('Button.Reload').toString();
      this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
      this.modalPopupContent = this.$t('Message.SessionVideoDeletedBy', {
        username: notification.UserName,
      }).toString();
      this.selectedItemId = notification.SelectedIds[0];
    }
  }
  // To update the VOD data
  private UpdateVodDetailForCustomCategoryDetails(notification: SignalRModelRequest) {
    const signalR: CustomCategorySignalRModel = new CustomCategorySignalRModel(
      Root,
      Store,
      this.$t,
    );
    if (
      this.selectedItems.length === 1 &&
        this.selectedItems[0].CustomCategoryId === notification.SelectedIds[0]
    ) {
    // if (!signalR.IsLoggedInUserIsMe(notification)) {
      if (notification.VodDetail !== null && notification.VodDetail !== undefined ) {
        if (notification.VodDetail.VodStatus === 'VideoUploadCreated' || notification.VodDetail.VodStatus === 'VideoUploadAssetCreated') {
          Root.emit('virtual-meeting-meetapp-live-analytics-available', true);
          this.AssetReady = false;
        } else if (notification.VodDetail.VodStatus === 'VideoAssetReady') {
          this.AssetReady = true;
          Root.emit('virtual-meeting-meetapp-live-analytics-available', false);
          if (notification.VodDetail.Id > 0 && notification.VodDetail.VodVideo! == null) {
            this.GetVideoFullDetails(notification.SelectedIds[0]);
          }
        }  else if (notification.VodDetail.VodStatus === 'VideoAssetCreated') {
          Root.emit('entity-item-video-upload-current-status', notification.VodDetail);
        } else {
          Root.emit('entity-item-video-upload-current-status', notification.VodDetail);
        }
        if (notification.VodDetail.VodStatus === 'VideoUploadCreated' || notification.VodDetail.VodStatus === 'VideoAssetReady' || notification.VodDetail.VodStatus === 'VideoAssetDeleted' || notification.VodDetail.VodStatus === 'VideoAssetUpdated') {
          if (signalR.IsLoggedInUserIsMe(notification)) {
            this.modalPopupVisible = true;
            this.showSectionModalPopup = this.NotificationVideoUploadedSuccessfully;
            this.modalPopupShowCancelButton = false;
            this.modalPopupConfirmButtonText = this.$t('Button.Reload').toString();
            this.modalPopupContent = this.$t('Message.ItemUpdatedBy', {
              username: notification.UserName,
            }).toString();
          } else {
            Root.emit('entity-item-video-upload-current-status', notification.VodDetail);
          }
        }
      } else {
        Root.emit('entity-item-video-upload-current-status', notification.VodDetail);
      }
    } else {
      Root.emit('entity-item-video-upload-current-status', notification.VodDetail);
    }
  }
  // Get VOD details once it get uploaded
  private GetVideoFullDetails(CustomerCategoryId: number) {
    FetchData('/CustomCategory/GetFullVodDetailAsyncUpdated', JSON.stringify({
      entityId: CustomerCategoryId,
      entityType: VodModuleTypeEnum[VodModuleTypeEnum.CustomCategory].toString(),
    })).then((response: VodDetail) => {
      const vodStatus: VodDetail  = response;
      Root.emit('entity-item-video-upload-current-status-api', vodStatus);
    }).catch((error: any) => {
      window.console.log(error);
    });
  }
  private ResetCustomCategoryItem() {
    Root.emit('reload-customCategory-detail');
  }
  // To unhighlight the information item
  private ResetItemsBackgroundColor() {
    this.customCategoryItems!.forEach((item) => {
      item.styleDefaultDiv = '#f9f9f9';
    });
  }
  private ConfirmClick(confirmed: boolean) {
    this.modalPopupVisible = false;
    if (confirmed) {
      if (this.showSectionModalPopup === this.WarningMessage) {
        if (this.fromCreateNew) {
          this.customCategoryItemDeletedOperation(this.selectedItemId);
          this.$emit(
            'custom-category-item-cancel-operation',
            CustomCategoryItem.createEmpty(),
          );
          Root.emit('customCategory-newItem-clicked');
        } else {
          const checkbox = document.getElementById(
            'pi_' + this.selectedItems[0].CustomCategoryId,
          ) as HTMLInputElement | null;
          if (checkbox != null) {
            checkbox.checked = false;
          }
          this.ResetItemsBackgroundColor();
          this.updateSelectedItem(this.selectedCustomCategoryItem);
          Root.emit(
            'customCategory-type',
            CustomCategoryTypeEnum[
              this.selectedCustomCategoryItem
                .ContentTypeV2 as keyof typeof CustomCategoryTypeEnum
            ],
          );
        }
        this.fromCreateNew = false;
      } else if (this.showSectionModalPopup === this.Notification) {
        this.customCategoryItemDeletedOperation(this.selectedItemId);
        this.$emit(
          'custom-category-item-cancel-operation',
          CustomCategoryItem.createEmpty(),
        );
        // empty selected item array if deleted
        this.selectedItems = [];
      } else if (
        this.showSectionModalPopup === this.NotificationMultimediaUpdated ||
        this.showSectionModalPopup ===
          this.NotificationMultimediaDeleteUpdated ||
        this.showSectionModalPopup === this.Notification360ImagesUpdated ||
        this.showSectionModalPopup === this.Notification360ImagesDeleteUpdated
      ) {
        // refresh customCategory detail page
        this.FillCustomCategoryDetails(this.selectedItems[0]);
      } else if (this.showSectionModalPopup === this.NotificationSorting) {
        Root.emit('set-custom-category-landing-page', true);
        Root.emit('reload-customCategories-data');
        this.ResetItemsBackgroundColor();
        this.$emit(
          'custom-category-item-cancel-operation',
          CustomCategoryItem.createEmpty(),
        );
        // empty selected item array if deleted
        this.selectedItems = [];
      } else if (
        this.showSectionModalPopup === this.NotificationSharedEventsUpdated ||
        this.showSectionModalPopup ===
          this.NotificationUpdateProgramListForInformationLinkingBulk
      ) {
        Root.emit('reload-customCategories-data');
        this.ResetItemsBackgroundColor();
        this.$emit(
          'custom-category-item-cancel-operation',
          CustomCategoryItem.createEmpty(),
        );
        // empty selected item array if deleted
        this.selectedItems = [];
      } else if (
        this.showSectionModalPopup ===
        this.NotificationUpdateProgramListForInformationLinking
      ) {
        this.FillCustomCategoryDetails(this.selectedItems[0]);
      } else if (this.showSectionModalPopup === this.NotificationUpdateFolder) {
        this.ResetItemsBackgroundColor();
        this.$emit(
          'custom-category-item-cancel-operation',
          CustomCategoryItem.createEmpty(),
        );
        // empty selected item array if deleted
        this.selectedItems = [];
      } else if (
        this.showSectionModalPopup ===
        this.NotificationUpdateFolderWithSelectedItem
      ) {
        // Do Nothing
      } else if (this.showSectionModalPopup === this.LinkedParticipant) {
        this.ResetCustomCategoryItem();
      } else if (this.showSectionModalPopup === this.NotificationVideoDeletedSuccessfully) {
        this.SelectItem(this.selectedItems[0]);
      }
    } else {
      this.oldUpdateSelectedItem(this.selectedCustomCategoryItemValue[0]);
      this.fromCreateNew = false;
    }
  }

  private UpdateMultimediaForCustomCategoryDetails(
    notification: SignalRModelRequest,
    customCategoryDetails: any,
  ) {
    const signalR: CustomCategorySignalRModel = new CustomCategorySignalRModel(
      Root,
      Store,
      this.$t,
    );
    if (signalR.IsLoggedInUserIsMe(notification)) {
      Root.emit(
        'customCategory-multimedia-item-edit-operation',
        customCategoryDetails.dataItem,
      );
    } else {
      if (
        this.selectedItems.length === 1 &&
        this.selectedItems[0].CustomCategoryId === notification.SelectedIds[0]
      ) {
        this.modalPopupVisible = true;
        this.showCloseButton = false;
        this.showSectionModalPopup = this.NotificationMultimediaUpdated;
        this.modalPopupShowCancelButton = false;
        this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
        this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
        this.modalPopupContent = this.$t('Message.ItemUpdatedBy', {
          username: notification.UserName,
        }).toString();
        this.customCategoryDetailInfo = customCategoryDetails.dataItem;
      } else {
        Root.emit(
          'customCategory-multimedia-item-edit-operation',
          customCategoryDetails.dataItem,
        );
      }
    }
  }
  private DeleteMultimediaForCustomCategoryDetails(
    notification: SignalRModelRequest,
    customCategoryDetails: any,
  ) {
    const signalR: CustomCategorySignalRModel = new CustomCategorySignalRModel(
      Root,
      Store,
      this.$t,
    );
    if (signalR.IsLoggedInUserIsMe(notification)) {
      Root.emit(
        'customCategory-multimedia-item-delete-operation',
        customCategoryDetails.id,
      );
    } else {
      if (
        this.selectedItems.length === 1 &&
        this.selectedItems[0].CustomCategoryId === notification.SelectedIds[0]
      ) {
        this.modalPopupVisible = true;
        this.showCloseButton = false;
        this.showSectionModalPopup = this.NotificationMultimediaDeleteUpdated;
        this.modalPopupShowCancelButton = false;
        this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
        this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
        this.modalPopupContent = this.$t('Message.ItemUpdatedBy', {
          username: notification.UserName,
        }).toString();
        this.selectedItemId = customCategoryDetails.id;
      } else {
        Root.emit(
          'customCategory-multimedia-item-delete-operation',
          customCategoryDetails.id,
        );
      }
    }
  }
  private UpdateLinkedParticipantsForCustomCategoryDetails(
    notification: SignalRModelRequest,
    customCategoryDetails: any,
  ) {
    const signalR: CustomCategorySignalRModel = new CustomCategorySignalRModel(
      Root,
      Store,
      this.$t,
    );
    if (signalR.IsLoggedInUserIsMe(notification)) {
      // Do nothing
    } else {
      // If operation is perforemd by user other than the logged in user
      if (
        this.selectedItems.length === 1 &&
        this.selectedItems[0].CustomCategoryId === notification.SelectedIds[0]
      ) {
        // If current open item is the item that got updated
        this.modalPopupVisible = true;
        this.showCloseButton = false;
        this.showSectionModalPopup = this.LinkedParticipant;
        this.modalPopupShowCancelButton = false;
        this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
        this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
        this.modalPopupContent = this.$t('Message.ItemUpdatedBy', {
          username: notification.UserName,
        }).toString();
        this.customCategoryDetailInfo = customCategoryDetails.dataItem;
      }
    }
  }
  private UpdateImage360ForCustomCategoryDetails(
    notification: SignalRModelRequest,
    customCategoryDetails: any,
  ) {
    const signalR: CustomCategorySignalRModel = new CustomCategorySignalRModel(
      Root,
      Store,
      this.$t,
    );
    if (signalR.IsLoggedInUserIsMe(notification)) {
      Root.emit(
        'customCategory-360image-item-edit-operation',
        customCategoryDetails.dataItem,
      );
    } else {
      if (
        this.selectedItems.length === 1 &&
        this.selectedItems[0].CustomCategoryId === notification.SelectedIds[0]
      ) {
        this.modalPopupVisible = true;
        this.showCloseButton = false;
        this.showSectionModalPopup = this.Notification360ImagesUpdated;
        this.modalPopupShowCancelButton = false;
        this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
        this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
        this.modalPopupContent = this.$t('Message.ItemUpdatedBy', {
          username: notification.UserName,
        }).toString();
        this.customCategoryDetailInfo = customCategoryDetails.dataItem;
      } else {
        Root.emit(
          'customCategory-360image-item-edit-operation',
          customCategoryDetails.dataItem,
        );
      }
    }
  }
  private DeleteImage360ForCustomCategoryDetails(
    notification: SignalRModelRequest,
    customCategoryDetails: any,
  ) {
    const signalR: CustomCategorySignalRModel = new CustomCategorySignalRModel(
      Root,
      Store,
      this.$t,
    );
    if (signalR.IsLoggedInUserIsMe(notification)) {
      Root.emit(
        'customCategory-image360-item-delete-operation',
        customCategoryDetails.id,
      );
    } else {
      if (
        this.selectedItems.length === 1 &&
        this.selectedItems[0].CustomCategoryId === notification.SelectedIds[0]
      ) {
        this.modalPopupVisible = true;
        this.showCloseButton = false;
        this.showSectionModalPopup = this.Notification360ImagesDeleteUpdated;
        this.modalPopupShowCancelButton = false;
        this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
        this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
        this.modalPopupContent = this.$t('Message.ItemUpdatedBy', {
          username: notification.UserName,
        }).toString();
        this.selectedItemId = customCategoryDetails.id;
      } else {
        Root.emit(
          'customCategory-360Image-item-delete-operation',
          customCategoryDetails.id,
        );
      }
    }
  }

  private customCategoryItemDeletedOperation(customCategoryId: number) {
    const objIndex = this.customCategoryItems!.findIndex(
      (obj: CustomCategoryItem) => obj.CustomCategoryId === customCategoryId,
    );
    if (objIndex > -1) {
      this.customCategoryItems!.splice(objIndex, 1);
      this.OnFolderChange(this.selectedFolderName);
    }
  }
  private CheckIfDeletedItemIsOpenInEditMode(
    notification: SignalRModelRequest,
  ) {
    const signalR: CustomCategorySignalRModel = new CustomCategorySignalRModel(
      Root,
      Store,
      this.$t,
    );
    if (signalR.IsLoggedInUserIsMe(notification)) {
      // If the operation is performed by same user then delete item without popup
      this.customCategoryItemDeletedOperation(notification.SelectedIds[0]);
    } else {
      // If the operation is performed by different user then delete item wuth showing popup
      this.modalPopupVisible = true;
      this.showCloseButton = false;
      this.showSectionModalPopup = this.Notification;
      this.modalPopupShowCancelButton = false;
      this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
      this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
      this.modalPopupContent = this.$t('Message.ItemDeletedBy', {
        username: notification.UserName,
      }).toString();
      this.selectedItemId = notification.SelectedIds[0];
    }
  }
  private CheckIfSortingItemIsOpenInEditMode(
    notification: SignalRModelRequest,
  ) {
    const signalR: CustomCategorySignalRModel = new CustomCategorySignalRModel(
      Root,
      Store,
      this.$t,
    );
    // Check if action is performed by same user
    if (signalR.IsLoggedInUserIsMe(notification)) {
      Root.emit('reload-customCategories-data');
    } else {
      // Show popup and reload
      this.modalPopupVisible = true;
      this.showCloseButton = false;
      this.showSectionModalPopup = this.NotificationSorting;
      this.notificationSignalRSorting = notification;
      this.modalPopupShowCancelButton = false;
      this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
      this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
      if (this.selectedItems.length === 1) {
        this.modalPopupContent = this.$t('Message.ItemUpdatedBy', {
          username: notification.UserName,
        }).toString();
      } else {
        this.modalPopupContent = this.$t('Message.MultipleItemsUpdatedBy', {
          username: notification.UserName,
        }).toString();
      }
    }
  }
  private CheckIfUpdatedItemIsOpenInEditMode(
    notification: SignalRModelRequest,
  ) {
    const signalR: CustomCategorySignalRModel = new CustomCategorySignalRModel(
      Root,
      Store,
      this.$t,
    );
    if (signalR.IsLoggedInUserIsMe(notification)) {
      // Do nothing
    } else {
      this.modalPopupVisible = true;
      this.showCloseButton = false;
      this.showSectionModalPopup = this.NotificationSharedEventsUpdated;
      this.modalPopupShowCancelButton = false;
      this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
      this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
      this.modalPopupContent = this.$t('Message.ItemUpdatedBy', {
        username: notification.UserName,
      }).toString();
      this.selectedItemId = notification.SelectedIds[0];
    }
  }

  // Draggable component on drag start event
  private draggingStart() {
    this.dragging = true;
  }
  // Draggable component on drag end event
  private draggingEnd() {
    this.dragging = false;
    this.SaveCustomCategorySortOrder();
  }
  private resetCustomCategoryItemsBackgroundColor() {
    if(this.customCategoryItems !== null && this.customCategoryItems !== undefined) {
      this.customCategoryItems.forEach((item)=> {
        item.styleDefaultDiv = '#f9f9f9';
      });
    }
  }
  private UncheckSelectAllCheckbox() {
    Root.emit('customCategory-uncheck-select-all');
    this.emptySelectedItems = false;
  }
  private updateSelectedItem(selectedCustomCategoryItem: CustomCategoryItem) {
    this.UncheckSelectAllCheckbox();
    // make background white of selected customCategory item in customCategory list.
    this.resetCustomCategoryItemsBackgroundColor();
    this.selectedItems = [];
    if (selectedCustomCategoryItem != null) {
      setTimeout(() => {
        selectedCustomCategoryItem.styleDefaultDiv = this.whiteColor;
      }, 100);
      this.selectedItems.push(selectedCustomCategoryItem);
      this.selectedCheckboxCount = 1;
      this.FillCustomCategoryDetails(selectedCustomCategoryItem);
    }
  }
  private oldUpdateSelectedItem(
    selectedCustomCategoryItem: CustomCategoryItem,
  ) {
    this.UncheckSelectAllCheckbox();
    // make background white of selected customCategory item in customCategory list.
    this.resetCustomCategoryItemsBackgroundColor();
    this.selectedItems = [];
    if (selectedCustomCategoryItem != null) {
      selectedCustomCategoryItem.styleDefaultDiv = this.whiteColor;
      this.selectedItems.push(selectedCustomCategoryItem);
      const checkbox = document.getElementById(
        'pi_' + selectedCustomCategoryItem.CustomCategoryId,
      ) as HTMLInputElement | null;
      if (checkbox != null) {
        checkbox.checked = true;
      }
    }
  }
  private FillCustomCategoryDetails(
    selectedCustomCategoryItem: CustomCategoryItem,
  ) {
    if(selectedCustomCategoryItem.CustomCategoryId !== 0) {
      setTimeout(() => {
        Root.emit('customCategoryDetailLoading', true);
      }, 100);
      // get listing object in customCategory detail - api calling
      let url = '';
      url =
      '/CustomCategory/GetCustomCategoriesDetail?customCategoryId=' +
      selectedCustomCategoryItem.CustomCategoryId;
      FetchData(url,'')
        .then((response) => {
          if (response.CustomCategoryId > 0) {
            const responseData = response;
            selectedCustomCategoryItem.modifedDate = responseData.Modified;
            selectedCustomCategoryItem.CustomCategoryMedias =
            responseData.CustomCategoryMedias;
            selectedCustomCategoryItem.CustomCategoryMedias360 =
            responseData.CustomCategoryMedias360;
            selectedCustomCategoryItem.LinkedPrograms =
            responseData.LinkablePrograms;
            selectedCustomCategoryItem.LinkedEvents = responseData.LinkedEvents!;
            selectedCustomCategoryItem.LinkedParticipantsItems =
            responseData.LinkedParticipants!;
            selectedCustomCategoryItem.ParticipantGroupIds =
            responseData.ParticipantGroupIds!;
            selectedCustomCategoryItem.LinkedParticipantsItems!.forEach(
              (x1) => (x1.IsAdded = true),
            );
            selectedCustomCategoryItem.VodDetail = responseData.VodDetail!;
            // emit values to components
            this.callrootlevelemits(selectedCustomCategoryItem);
          } else {
            this.callrootlevelemits(selectedCustomCategoryItem);
            Root.emit('customCategoryDetailLoading', false);
            Root.emit('customCategoryDetailLoadingVod', false);
          }
          this.selectedCustomCategoryItemValue = [];
          this.selectedCustomCategoryItemValue.push(selectedCustomCategoryItem);
          Root.emit('showSharedEventsLink');
        })
        .catch(() => {
          Root.emit('customCategoryDetailLoading', false);
          Root.emit('customCategoryDetailLoadingVod', false);
        });
    }
  }
  private callrootlevelemits(
    customCategoryDetailViewModel: CustomCategoryItem,
  ) {
    Root.emit(
      'selected-participant-group-is',
      customCategoryDetailViewModel.ParticipantGroupIds,
    );
    Root.emit(
      'customCategory-multimediaData',
      customCategoryDetailViewModel.CustomCategoryMedias,
    );
    Root.emit(
      'customCategory-image360Data',
      customCategoryDetailViewModel.CustomCategoryMedias360,
    );
    Root.emit(
      'linkedProgramData',
      customCategoryDetailViewModel.LinkedPrograms,
    );
    Root.emit(
      'update-customCategory-details',
      customCategoryDetailViewModel,
    );
    Root.emit(
      'linkedParticipantsData',
      customCategoryDetailViewModel.LinkedParticipantsItems,
    );
    this.SetVodDetail(customCategoryDetailViewModel);
    Root.emit(
      'VODCustomCategoryData',
      this.selectedCustomCategoryVODItem,
    );
    const vodMuxVideoData: VodMuxVideoData = VodMuxVideoData.createEmpty();
    vodMuxVideoData.Id = customCategoryDetailViewModel.CustomCategoryId;
    vodMuxVideoData.Title = customCategoryDetailViewModel.Title;
    vodMuxVideoData.VideoDetails = this.selectedCustomCategoryVODItem.VodDetail;
    vodMuxVideoData.VirtualMeetingType = VodTypeEnums.Mux.toString();
    Root.emit(
      'VodMuxVideoData',
      vodMuxVideoData,
    );
    Root.emit(
      'customCategory-type',
      CustomCategoryTypeEnum[
        customCategoryDetailViewModel
          .ContentTypeV2 as keyof typeof CustomCategoryTypeEnum
      ],
    );
    // make loading false
    Root.emit('customCategoryDetailLoadingVod', false);
    Root.emit('customCategoryDetailLoading', false);
  }
  private SetVodDetail(customCategoryDetailViewModel: CustomCategoryItem) {
    this.selectedCustomCategoryVODItem.Id = customCategoryDetailViewModel.CustomCategoryId;
    this.selectedCustomCategoryVODItem.Title = customCategoryDetailViewModel.Title;
    this.selectedCustomCategoryVODItem.VodDetail = customCategoryDetailViewModel.VodDetail!;
  }

  private ClearAllCheckBoxes() {
    this.selectedItems = [];
  }
  private updated() {
    const missingImage = this.missingImage;
    this.customCategoryItems!.forEach((item) => {
      if (item.IconUrl === '' || item.IconUrl === null) {
        item.IconUrl = missingImage;
      }
    });
  }
  private BindFoldersDropdown(value?: string[]) {
    // load folder dropdown
    if (value) {
      this.customCategoryFolders = Object.assign(value);
      // remove empty value
      if (this.customCategoryFolders) {
        this.customCategoryFolders = this.customCategoryFolders.filter(
          (e) => e !== '' && e !== this.All,
        );
      }
      // set Top level by default
      this.OnFolderChange(value[0]);
      this.selectedFolderName = value[0];
    }
  }
  private CheckCustomCategoryItems(
    val: CustomCategoryItem,
    index: number,
    event: any,
  ) {
    Root.emit('hide-showSearchModule');
    this.UncheckSelectAllCheckbox();
    setTimeout(() => {
      this.resetCustomCategoryItemsBackgroundColor();
    }, 100);
    const programItemChecked = event.target.checked;
    if (programItemChecked) {
      this.selectedCheckboxCount += 1;
    } else {
      this.selectedCheckboxCount -= 1;
    }
    if (this.selectedCheckboxCount === 1) {
      // Show the item in the edit pane
      if (programItemChecked) {
        this.SelectItem(val);
      } else {
        this.temporarySelectedItems = this.selectedItems;
        this.temporarySelectedItems = this.temporarySelectedItems.filter(
          (x) => x.CustomCategoryId !== val.CustomCategoryId,
        );
        this.SelectItem(this.temporarySelectedItems[0]);
      }
    }
  }
  private getMoveToAnotherItemMessage(): string {
    return this.$t('ChangeScreenMessage').toString();
  }
  // Get information in selected folder
  private GetSelectedFolderInformation(selectedFolderName: string) {
    const self = this;
    self.filteredCustomCategoryItems = [];
    this.customCategoryItems!.forEach((value) => {
      if (
        value.CategoryFolder &&
        value.CategoryFolder.Name === selectedFolderName
      ) {
        self.filteredCustomCategoryItems.push(value);
      }
    });
    if (self.filteredCustomCategoryItems.length === 0) {
      this.customCategoryFolders = this.customCategoryFolders!.filter(
        (e) => e !== selectedFolderName,
      );
      this.OnFolderChange(this.All);
      this.selectedFolderName = this.All;
    }
  }
  // Get information in top Level of folder
  private GetTopLevelInformations() {
    const self = this;
    self.filteredCustomCategoryItems = [];
    this.customCategoryItems!.forEach((value) => {
      if (value.CategoryFolder == null || value.CategoryFolder.Name === '') {
        value.CategoryFolder = null;
        self.filteredCustomCategoryItems.push(value);
      }
    });
  }
  // Get all the information item in the listing screen
  private GetAllInformations() {
    const self = this;
    self.filteredCustomCategoryItems = [];
    this.customCategoryItems!.forEach((value) => {
      if (value.CategoryFolder == null || value.CategoryFolder.Name === '') {
        value.CategoryFolder = null;
      }
      self.filteredCustomCategoryItems.push(value);
    });
  }
  // Getting list of information item corresponding to the selected folder
  private OnFolderChange(selectedFolderName: string) {
    if (selectedFolderName !== undefined && this.customCategoryItems != null) {
      Root.emit('customCategory-uncheck-select-all');
      this.selectedItems = [];
      this.selectedCheckboxCount = 0;
      this.resetCustomCategoryItemsBackgroundColor();
      if (selectedFolderName === this.All) {
        this.GetAllInformations();
      } else if (selectedFolderName === '') {
        this.GetTopLevelInformations();
      } else {
        this.GetSelectedFolderInformation(selectedFolderName);
      }
    }
  }

  private OnFolderChangeWithWatch(selectedFolderName: string) {
    if (selectedFolderName === this.All) {
      this.GetAllInformations();
    } else if (selectedFolderName === '') {
      this.GetTopLevelInformations();
    } else {
      this.GetSelectedFolderInformation(selectedFolderName);
    }
  }
  private OpenCreateNewQuestionpane() {
    this.$emit('create-new-customCategory-click', '');
  }
  @Watch('folders')
  private onFoldersPropertyChange(val: string[]) {
    this.BindFoldersDropdown(val);
  }
  // To save the order of information item listing
  private SaveCustomCategorySortOrder() {
    Root.emit('hide-showSearchModule');
    const customCategoryIds: number[] = [];
    this.filteredCustomCategoryItems.forEach((element) => {
      customCategoryIds.push(element.CustomCategoryId);
    });
    this.showLoading = true;
    // call signalr
    const signalObject = this.SendNotificationRequest();
    FetchData('/CustomCategory/SetOrder', JSON.stringify({
      idsInOrders: customCategoryIds.toString(),
    }))
      .then(() => {
        this.UpdateSorting();
        this.$emit('update-sorting-custom-category-listing', {
          filterCustomCategoryItems: this.filteredCustomCategoryItems,
          signalObject,
        });
        this.showLoading = false;
      })
      .catch((error: any) => {
        window.console.log(error);
        this.showLoading = false;
      });
  }
  private SortCustomCategoryListing() {
    // ascending sorting
    this.filteredCustomCategoryItems.sort(
      (a: CustomCategoryItem, b: CustomCategoryItem): number => {
        if (a.Title.toLowerCase() > b.Title.toLowerCase()) {
          return 1;
        }
        if (b.Title.toLowerCase() > a.Title.toLowerCase()) {
          return -1;
        }
        return 0;
      },
    );
    // call api
    this.SaveCustomCategorySortOrder();
  }
  private SortCustomCategoryListingDesc() {
    // descending sorting
    this.filteredCustomCategoryItems.sort(
      (a: CustomCategoryItem, b: CustomCategoryItem): number => {
        if (a.Title.toLowerCase() > b.Title.toLowerCase()) {
          return -1;
        }
        if (b.Title.toLowerCase() > a.Title.toLowerCase()) {
          return 1;
        }
        return 0;
      },
    );
    // call api
    this.SaveCustomCategorySortOrder();
  }
  private UpdateSorting() {
    this.filteredCustomCategoryItems.forEach((element, index) => {
      element.SortOrder = index;
    });
  }
  private SelectItem(selectedCustomCategoryItem: CustomCategoryItem) {
    this.fromCreateNew = false;
    Root.emit('show-add-video-section-byEntity', false);
    Root.emit('hide-ShareWithOtherEventsProcess');
    Root.emit('hide-prerecorded-analytics');
    Root.emit('customCategoryDetailLoadingVod', true);
    Root.emit('reload-customCategory-hidegraph');
    if (this.selectedItems.length === 1) {
      this.$emit('change-custom-category-item', {
        selectedItem: selectedCustomCategoryItem,
        moveToAnotherTab: false,
      });
      this.selectedCustomCategoryItem = selectedCustomCategoryItem;
    // Jira 4924
    // this.updateSelectedItem(selectedCustomCategoryItem);
    } else {
      this.updateSelectedItem(selectedCustomCategoryItem);
      Root.emit(
        'customCategory-type',
        CustomCategoryTypeEnum[
          selectedCustomCategoryItem.ContentTypeV2 as keyof typeof CustomCategoryTypeEnum
        ],
      );
    }
  }
  @Watch('editingCustomCategoryItem')
  private ChangeEditingProgramItem(oldVal: CustomCategoryItem) {
    let selectedCustomCategory = this.selectedItems[0];
    if (this.selectedItems.length > 1) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      selectedCustomCategory = this.selectedCustomCategoryItem;
    }
    let oldLocalCustomCategoryItem = this.customCategoryItems!.find(
      (x) => x.CustomCategoryId === oldVal.CustomCategoryId,
    );
    if (
      oldLocalCustomCategoryItem === undefined &&
      oldVal.CustomCategoryId === 0
    ) {
      oldLocalCustomCategoryItem = this.selectedCustomCategoryItem;
      oldLocalCustomCategoryItem.ContentTypeV2 =
        oldLocalCustomCategoryItem.ContentTypeV2 === ''
          ? oldVal.ContentTypeV2
          : oldLocalCustomCategoryItem.ContentTypeV2;
    }
    if (oldLocalCustomCategoryItem !== undefined) {
      if (
        CustomCategoryItem.prototype.CompareCustomCategoryObjects({
          val1: oldVal,
          val2: oldLocalCustomCategoryItem,
        })
      ) {
        this.updateSelectedItem(this.selectedCustomCategoryItem);
        Root.emit(
          'customCategory-type',
          CustomCategoryTypeEnum[
            this.selectedCustomCategoryItem
              .ContentTypeV2 as keyof typeof CustomCategoryTypeEnum
          ],
        );
        if (oldVal.CustomCategoryId === 0 || this.selectedCustomCategoryItem.CustomCategoryId === 0 || selectedCustomCategory.CustomCategoryId === 0) {
          Root.emit('customCategory-newItem-clicked');
        }
      } else {
        if(this.localCreateNewUnsavedFlag === true && oldVal.CustomCategoryId === 0) {
          this.updateSelectedItem(this.selectedCustomCategoryItem);
          Root.emit(
            'customCategory-type',
            CustomCategoryTypeEnum[
              this.selectedCustomCategoryItem
                .ContentTypeV2 as keyof typeof CustomCategoryTypeEnum
            ],
          );
        } else {
          this.FillCustomCategoryDetails(oldVal);
          this.showSectionModalPopup = this.WarningMessage;
          this.modalPopupVisible = true;
          this.modalPopupContent = this.GetMoveToAnotherItemMessage();
          this.SetModalPopupDefaultButtonText();
          this.showCloseButton = true;
          this.selectedCustomCategoryItemValue = [];
          this.selectedCustomCategoryItemValue.push(oldVal);
          this.updateSelectedItem(oldVal);
        }
      }
    } else {
      this.updateSelectedItem(this.selectedCustomCategoryItem);
      Root.emit(
        'customCategory-type',
        CustomCategoryTypeEnum[
          this.selectedCustomCategoryItem
            .ContentTypeV2 as keyof typeof CustomCategoryTypeEnum
        ],
      );
    }
  }
  private GetMoveToAnotherItemMessage(): string {
    return this.$t('ChangeScreenMessage').toString();
  }
  // Set default text for modal popup buttons
  private SetModalPopupDefaultButtonText() {
    this.modalPopupShowCancelButton = true;
    this.modalPopupConfirmButtonText = this.$t('YesButton').toString();
    this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
  }

  @Watch('selectedItems')
  private onPropertyChange(val: CustomCategoryItem[]) {
    this.$emit('customCategory-items-selected', val);
    // emit property if diffirent program item selected and scroll will go to top
    Root.emit('new-customCategory-edit-item-pane', true);
  }
  @Watch('selectAllItems')
  private onPropertySelectAllItemsChange(val: boolean) {
    this.fromCreateNew = false;
    Root.emit('hide-showSearchModule');
    if (this.customCategoryItems) {
      if (val === true) {
        this.selectedItems = [];
        this.selectedCheckboxCount = 0;
        this.filteredCustomCategoryItems.forEach((item) => {
          this.selectedItems.push(item);
          this.selectedCheckboxCount += 1;
        });
        if (this.selectedItems.length === 1) {
          this.FillCustomCategoryDetails(this.selectedItems[0]);
        }
      } else {
        if (this.emptySelectedItems) {
          this.selectedItems = [];
          this.selectedCheckboxCount = 0;
        }
      }
      this.emptySelectedItems = true;
    }
  }
  // Update selected folder
  private UpdateSelectedFolder(name: string) {
    this.selectedFolderName = name;
    // this.OnFolderChange(this.selectedFolderName);
  }
  @Watch('customCategoryItems',{deep: true})
  private oncustomCategoryItemsPropertyChange(val: CustomCategoryItem[]) {
    this.UpdateFolderList(val);
    this.OnFolderChangeWithWatch(this.selectedFolderName);
  }
  // To update the folder listing
  private UpdateFolderList(customCategoryItems: CustomCategoryItem[]) {
    const self = this;
    self.customCategoryFolders = [];
    customCategoryItems.forEach((customCategoryItem: CustomCategoryItem) => {
      if (
        customCategoryItem.CategoryFolder &&
        customCategoryItem.CategoryFolder.Name !== ''
      ) {
        if (
          self.customCategoryFolders &&
          !self.customCategoryFolders.includes(
            customCategoryItem.CategoryFolder.Name,
          )
        ) {
          self.customCategoryFolders.push(
            customCategoryItem.CategoryFolder.Name,
          );
        }
      }
    });
  }
  // #region SignalR
  private SendNotificationRequest(): SignalRModelRequest {
    setTimeout(() => {
      Root.emit('show-notification-popup', true);
      Root.emit('show-notification-loading', true);
    }, 100);
    const signalR: CustomCategorySignalRModel = new CustomCategorySignalRModel(
      Root,
      Store,
      this.$t,
    );
    let signalObject: SignalRModelRequest = new SignalRModelRequest();
    signalObject = signalR.SortingCustomCategoryItemSignalRModelRequest;
    const arr: number[] = [];
    this.filteredCustomCategoryItems.forEach((customCategoryItem) => {
      arr.push(customCategoryItem.CustomCategoryId);
      signalObject.SelectedIds = arr;
    });

    signalObject.Description = this.$t(signalObject.SubmitMessage, {
      user: signalR.GetUserName(
        signalObject,
        this.$t('UserName.You').toString(),
      ),
    }).toString();
    const storeHelper: StoreHelper = new StoreHelper(Store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    signalObject.ApplicationId = Number(reqInfo.ApplicationId);
    signalObject.EventId = Number(reqInfo.ApplicationInstanceId);
    signalObject.JSONData = JSON.stringify(this.filteredCustomCategoryItems);
    signalR.DisableCustomCategoryItemsAndAddToNotificationPopup(signalObject);
    return signalObject;
  }

  // #endregion
  // To prevent continuous calling of root on
  private beforeUnmount() {
    Root.off('update-programs-linking-in-information');
    Root.off('customCategory-item-bulk-delete-operation');
    Root.off('customCategory-item-single-delete-operation');
    Root.off('customCategory-item-bulk-imageUrl-operation');
    Root.off('selected-customCategory-item-notification-completed');
    Root.off('update-folder-in-listing');
    Root.off('update-program-in-listing');
  }

  private SelectedTypeFormat(val: string) {
    // format content type for list view
    if (val === 'OtherInformation') {
      return 'Other information';
    } else if (val === 'SocialMedia') {
      return 'Social media';
    } else if (val === 'Webpage') {
      return 'Web Page';
    }else if (val === 'Presentation') {
      return 'PDF Document';
    } else {
      return val;
    }
  }

  @Watch('createNewUnsavedFlag')
  private oncreateNewUnsavedFlagchanged(
    value: boolean,
  ){
    this.localCreateNewUnsavedFlag = value;
  }
}
export default toNative(CustomCategoryListView);
