
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
import { CustomCategoryFolder } from '@/entities/CustomCategory/CustomCategoryFolder';
import CustomCategoryFolderEditItemPane from '@/components/CustomCategory/CustomCategoryDetail/Folders/CustomCategoryFolderEditItemPane.vue';
import loading from '@/components/Common/loading.vue';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import {SignalRModelRequest} from '@/signalRRequest/SignalRModelRequest';
import {FolderSignalRModel} from '@/signalRRequest/CustomCategory/FolderSignalRModel';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { FetchData, Root, Store } from '@/main';
@Component({
  components: {
    CustomCategoryFolderEditItemPane,
    loading,
    ModalPopup,
  },
})
class CustomCategoryFolderListViewEditItemPane extends Vue {
  @Prop() private selectedEditFolderItem?: CustomCategoryFolder;
  @Prop() private applicationborderColor?: string;
  private localFolderItem: CustomCategoryFolder | null = null;
  private missingImage: string = require('@/assets/Images/ImageMissing.png');
  private missingPinImage: string = require('@/assets/Images/ImageMissingPin.png');
  private deleteIcon = require('@/assets/Images/Delete-icon.svg');
  private showEditFolder?: boolean = false;
  private loaderBorderColor = '';
  private showLoading = false;
  private isMobileView = false;
  private modalPopupVisible = false;
  private showCloseButton = true;
  private modalPopupContent: string = this.GetRemoveFolderMessage();
  private folderIdTobeDeleted = 0;
  private folderNameTobeDeleted = '';

  @Watch('selectedEditFolderItem')
  private onchange(val: CustomCategoryFolder) {
    if (val.Id > 0) {
      this.localFolderItem!.Name = val.Name;
      this.localFolderItem!.IconUrl = val.IconUrl;
      this.localFolderItem!.IconUrlOriginal = val.IconUrlOriginal;
      this.localFolderItem!.PinnedToMenu = val.PinnedToMenu;
      this.localFolderItem!.FontAwesomeIconId = val.FontAwesomeIconId;
      this.localFolderItem!.FontAwesomeIconCode = val.FontAwesomeIconCode;
      this.localFolderItem!.FontAwesomeIconName = val.FontAwesomeIconName;
    }
  }
  private mounted() {
    if (this.selectedEditFolderItem != null) {
      this.localFolderItem = this.selectedEditFolderItem;
    }
    if (this.applicationborderColor != null) {
      this.loaderBorderColor = this.applicationborderColor;
    }
    const self = this;
    Root.on(
      'customCategory-folder-item-imageupdated-operation',
      (pt: CustomCategoryFolder) => {
        if (pt != null) {
          if (pt.Id === self.localFolderItem!.Id) {
            self.selectItem(pt);
          }
        }
      },
    );
    // check for ipad and mobile view
    if (window.innerWidth <= 1300) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }

    Root.on('customCategory-folder-close-others-operation', () => {
      this.showEditFolder = false;
    });
  }

  private GetImageUrl(imgurl: string) {
    if (imgurl === null || imgurl === '') {
      if (this.localFolderItem && this.localFolderItem.FontAwesomeIconId === 0) {
        return this.missingImage;
      } else {
        return this.missingPinImage;
      }
    }
    return imgurl;
  }
  private getEditButtonstyle(isMobileview: boolean) {
    if (isMobileview) {
      return {
        width: 'auto',
      };
    } else {
      return {};
    }
  }
  private getLeftDivStyle(isMobileview: boolean) {
    if (isMobileview) {
      return {
        width: '48%',
      };
    } else {
      return { width: '60%' };
    }
  }
  private getRightDivStyle(isMobileview: boolean) {
    if (isMobileview) {
      return {
        width: '52%',
      };
    } else {
      return { width: '40%' };
    }
  }
  private getDeleteButtonstyle(isMobileview: boolean) {
    if (isMobileview) {
      return {
        'margin-left': '5px',
        'height': 'auto',
        'width': 'auto',
      };
    } else {
      return { 'margin-left': '15px' };
    }
  }
  private CustomCategoryFolderItemImageEditedOperation(val: CustomCategoryFolder) {
    this.selectItem(val);
  }
  private selectItem(val: CustomCategoryFolder) {
    Root.emit('customCategory-folder-close-others-operation');
    const selectedItem = val;
    this.localFolderItem = selectedItem;
    this.showEditFolder = true;
    this.$emit('customCategory-folder-item-edit-operation', selectedItem);
    this.scrollToElement();
  }
  private CustomCategoryFolderItemCancelOperation(pt: CustomCategoryFolder) {
    this.localFolderItem = pt;
    this.showEditFolder = false;
  }

  private CustomCategoryFolderItemEditedOperation(pt: CustomCategoryFolder) {
    this.localFolderItem = pt;
    this.showEditFolder = false;
  }

  private CustomCategoryFolderItemImageUploadOperation(val: boolean) {
    this.$emit('customCategory-folder-item-imageUpload-operation', val);
  }
  private FolderItemFontUploadOperation(val: boolean) {
    this.$emit('folder-information-fonticon', val);
  }

  // Remove Folder with modal popup
  private GetRemoveFolderMessage(): string {
    return this.$t('Folder.Delete.Message').toString();
  }
  private ConfirmClick(confirmed: boolean) {
    this.modalPopupVisible = false;
    if (confirmed) {
      this.Delete(this.folderIdTobeDeleted);
    } else {
      return;
    }
  }
  private DeleteSelectedFolder(folder: CustomCategoryFolder) {
    this.folderIdTobeDeleted = folder.Id;
    this.folderNameTobeDeleted = folder.Name;
    this.modalPopupVisible = true;
  }
  private SendNotificationRequest(): SignalRModelRequest {
    setTimeout(() => {
      Root.emit('show-notification-popup', true);
      Root.emit('show-notification-loading', true);
    }, 100);
    const signalR: FolderSignalRModel = new FolderSignalRModel(
      Root,
      Store,
      this.$t,
    );
    let signalObject: SignalRModelRequest = new SignalRModelRequest();
    signalObject = signalR.SingleDeleteFolderItemSignalRModelRequest;
    const arr: number[] = [];
    arr.push(this.localFolderItem!.Id);
    signalObject.SelectedIds = arr;
    signalObject.Description = this.$t(signalObject.SubmitMessage, {
      selectedCount: arr.length, // Just single item delete
      user: signalR.GetUserName(
        signalObject,
        this.$t('UserName.You').toString(),
      ),
    }).toString();
    const storeHelper: StoreHelper = new StoreHelper(Store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    signalObject.ApplicationId = Number(reqInfo.ApplicationId);
    signalObject.EventId = Number(reqInfo.ApplicationInstanceId);
    signalObject.JSONData = JSON.stringify({
      selectedName: this.folderNameTobeDeleted,
    });
    signalR.AddToNotificationPopup(signalObject);
    return signalObject;
  }
  private SendNotificationRequestToQueue(
    signalObject: SignalRModelRequest,
    customCategoryFolderItem: CustomCategoryFolder,
  ) {
    const signalR: FolderSignalRModel = new FolderSignalRModel(
      Root,
      Store,
      this.$t,
    );
    // Case of Single Create as we get the id once response come from DB
    if (signalObject.SelectedIds.length === 0) {
      const arr: number[] = [];
      arr.push(customCategoryFolderItem.Id);
      signalObject.SelectedIds = arr;
    }
    signalObject.JSONData = signalR.GetFolderItemSendToSignalR(customCategoryFolderItem);
    signalR.PerformFolderSingleOperation(signalObject);
  }
  private Delete(folderId: number) {
    const signalObject = this.SendNotificationRequest();
    FetchData('/CustomCategory/DeleteCustomCategoryFolder', JSON.stringify({
      customCategoryFolderId: folderId,
    }))
      .then((response) => {
        if (response.success === false) {
          // Server side validation failuere
          alert(this.$t('Message.UnableToDelete'));
        } else {
          // Successful delete
          this.SendNotificationRequestToQueue(
            signalObject,
            this.localFolderItem!,
          );
          // Successful update / save
          Root.emit('customCategory-folder-item-delete-operation', folderId);
        }
      })
      .catch(() => {
        // Log error if required
      });
  }
  // Scroll to particular element when come back from image process component
  private scrollToElement() {
    setTimeout(() => {
      const el = document.getElementById('customCategoryFolderItemEditPane');
      el!.scrollIntoView({
        block: 'nearest',
        behavior: 'smooth',
        inline: 'nearest',
      });
    }, 100);
  }
}
export default toNative(CustomCategoryFolderListViewEditItemPane);
