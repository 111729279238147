
import { Component, Prop, toNative, Vue } from 'vue-facing-decorator';
import CustomCategoryFolderEditItemPane from '@/components/CustomCategory/CustomCategoryDetail/Folders/CustomCategoryFolderEditItemPane.vue';
import CustomCategoryFolderListViewEditItemPane from '@/components/CustomCategory/CustomCategoryDetail/Folders/CustomCategoryFolderListViewEditItemPane.vue';
import ImageProcess from '@/components/Common/ImageProcess.vue';
import { setTimeout } from 'timers';
import { CustomCategoryFolder } from '../../../../entities/CustomCategory/CustomCategoryFolder';
import { FontAwesomeIcon } from '../../../../entities/FontAwesomeViewModel';
import InformationFontIcon from '@/components/Common/InformationFontIcon.vue';
import Common from '@/helper/Common';
import { Root } from '@/main';
@Component({
  components: {
    CustomCategoryFolderEditItemPane,
    CustomCategoryFolderListViewEditItemPane,
    ImageProcess,
    InformationFontIcon,
  },
})
class CustomCategoryFolderListView extends Vue {
  @Prop() private folderItems?: CustomCategoryFolder[];
  @Prop() private selectedFolderItem?: CustomCategoryFolder;
  @Prop() private applicationborderColor?: string;
  @Prop() private fontAwesomeIcons?: FontAwesomeIcon[];
  private localFolderItems: CustomCategoryFolder[] = [];
  private missingImage: string = require('@/assets/Images/ImageMissing.png');
  private localFolderItem: CustomCategoryFolder | null = null;
  private currentEditFolderItem: CustomCategoryFolder | null = null;
  private showImageProcess = false;
  private showFontAwesomeIconImageProcess = false;
  private isMobileView = false;
  public loaderBorderColor = '';
  private GetImageUrl() {
    let folderItem;
    if (this.currentEditFolderItem != null) {
      folderItem = this.currentEditFolderItem;
    } else {
      folderItem = this.localFolderItem;
    }
    return !folderItem!.IconUrl ? this.missingImage : folderItem!.IconUrl;
  }
  private ClosePreviouslyOpenFoldersForEdit() {
    Root.emit('customCategory-folder-close-others-operation');
  }
  private AddFolder() {
    this.ClosePreviouslyOpenFoldersForEdit();
    const newItem = CustomCategoryFolder.createEmpty();
    this.localFolderItem = newItem;
    this.RedirectToItem(this.localFolderItems.length);
  }
  private CloseFolder() {
    this.localFolderItem = null;
    this.$emit('customCategory-folder-close-operation', true);
    Root.emit('clearCustomCategoryCheckboxes', []);
  }
  private mounted() {

    this.localFolderItems = this.folderItems!;

    const objIndex = this.localFolderItems.findIndex(

      (obj: CustomCategoryFolder) => (obj.Id === 0 && obj.Name === this.$t('ProgramAddInformation.TopLevel').toString()),
    );
    if (objIndex > -1) {
      this.localFolderItems.splice(objIndex, 1);
    }
    // check for ipad and mobile view
    if (window.innerWidth <= 767) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    if (this.applicationborderColor != null) {
      this.loaderBorderColor = this.applicationborderColor;
    }
    Root.on(
      'customCategory-folder-item-edit-operation',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (pt: CustomCategoryFolder) => {
        this.CustomCategoryFolderItemCancelOperation();
      },
    );

    Root.on('customCategory-folder-close-others-operation', () => {
      this.currentEditFolderItem = null;
      this.localFolderItem = null;
    });
    // move scroll to top when this pane will open.
    if (!this.isMobileView) {
      Common.ScrollToTop('customCategoryListViewPane');
    }
  }
  private UpdateFontAwesomeIcon(fonticon: FontAwesomeIcon) {
    if (this.localFolderItem != null) {
      this.localFolderItem.FontAwesomeIconId = fonticon.Id;
      this.localFolderItem.FontAwesomeIconCode = fonticon.IconCode;
      this.localFolderItem.FontAwesomeIconName = fonticon.IconName;
    }  else if (this.currentEditFolderItem != null) {
      this.currentEditFolderItem.FontAwesomeIconId = fonticon.Id;
      this.currentEditFolderItem.FontAwesomeIconCode = fonticon.IconCode;
      this.currentEditFolderItem.FontAwesomeIconName = fonticon.IconName;
    }
  }
  private GetCustomCategoryFolder(): CustomCategoryFolder {
    if (this.localFolderItem != null) {
      return this.localFolderItem;
    }  else {
      return this.currentEditFolderItem!;
    }
  }
  private SetCurrentlocalItem(folder: CustomCategoryFolder) {
    this.currentEditFolderItem = folder;
  }
  private CustomCategoryFolderItemCancelOperation() {
    this.localFolderItem = null;
  }

  private CustomCategoryFolderItemImageUploadCompleted(pt: CustomCategoryFolder) {
    this.localFolderItem = pt;
  }
  private GetImageUploadUrl() {
    let url = this.missingImage;
    if (this.currentEditFolderItem != null) {
      url =
        this.currentEditFolderItem.IconUrlOriginal === null
          ? this.missingImage
          : this.currentEditFolderItem.IconUrlOriginal === ''
            ? this.missingImage
            : this.currentEditFolderItem.IconUrlOriginal;
    } else if (this.localFolderItem != null) {
      url =
        this.localFolderItem.IconUrlOriginal === null
          ? this.missingImage
          : this.localFolderItem.IconUrlOriginal === ''
            ? this.missingImage
            : this.localFolderItem.IconUrlOriginal;
    }
    return url;
  }
  private clickOnImage(val: boolean) {
    this.showImageProcess = val;
    if (val === true) {
      if (this.currentEditFolderItem != null) {
        this.currentEditFolderItem.FontAwesomeIconId = 0;
        this.currentEditFolderItem.FontAwesomeIconName = '';
      }
      const el = this.$el.getElementsByClassName('flex-container')[0];
      if (el) {
        el.scrollIntoView();
      }
    } else {
      this.scrollToElement();
    }

    if (this.currentEditFolderItem != null && val === false) {
      this.currentEditFolderItem.PinnedToMenu = false;
      setTimeout(() => {
        Root.emit(
          'customCategory-folder-item-imageupdated-operation',
          this.currentEditFolderItem,
        );
      }, 100);
    }
  }
  private clickOnFontImage(val: boolean) {
    this.showFontAwesomeIconImageProcess = val;
    if (val === true) {
      if (this.currentEditFolderItem != null) {
        this.currentEditFolderItem.PinnedToMenu = true;
      }
      const el = this.$el.getElementsByClassName('flex-container')[0];
      if (el) {
        el.scrollIntoView();
      }
    } else {
      this.scrollToElement();
    }

    if (this.currentEditFolderItem != null && val === false) {
      setTimeout(() => {
        Root.emit(
          'customCategory-folder-item-imageupdated-operation',
          this.currentEditFolderItem,
        );
      }, 100);
    }
  }
  private SetImageIconUrl(iconUrl: string, iconUrlOriginal: string) {
    if (this.localFolderItem != null) {
      this.localFolderItem.IconUrl = iconUrl;
      this.localFolderItem.IconUrlOriginal = iconUrlOriginal;
    } else if (this.currentEditFolderItem != null) {
      this.currentEditFolderItem.IconUrl = iconUrl;
      this.currentEditFolderItem.IconUrlOriginal = iconUrlOriginal;
    }
  }
  private GetPreviousIconUrl(iconUrl: string, iconUrlOriginal: string) {
    if (this.localFolderItem != null) {
      this.localFolderItem.OldIconUrl = iconUrl;
      this.localFolderItem.OldIconUrlOriginal = iconUrlOriginal;
    } else if (this.currentEditFolderItem != null) {
      this.currentEditFolderItem.OldIconUrl = iconUrl;
      this.currentEditFolderItem.OldIconUrlOriginal = iconUrlOriginal;
    }
  }
  // scroll to particular element when come back from image process component
  private scrollToElement() {
    setTimeout(() => {
      const el = document.getElementById('customCategoryFolderItemEditPane');
      if (el) {
        el.scrollIntoView({
          block: 'nearest',
          behavior: 'smooth',
          inline: 'nearest',
        });
      }
    }, 100);
  }
  private RedirectToItem(index: number) {
    setTimeout(() => {
      const element = document.querySelectorAll('[indexFolder="' + index + '"]')[0];
      if (element) {
        element.scrollIntoView({
          block: 'end',
          behavior: 'smooth',
          inline: 'end',
        });
      }
    }, 100);
  }
}
export default toNative(CustomCategoryFolderListView);
